import { useRef, useEffect } from "react";

export default function useOnMount(fn) {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return fn();
    }
  }, [fn]);
}
