export default [
  "123456",
  "12345",
  "123456789",
  "password",
  "iloveyou",
  "princess",
  "1234567",
  "rockyou",
  "12345678",
  "abc123",
  "nicole",
  "daniel",
  "babygirl",
  "monkey",
  "lovely",
  "jessica",
  "654321",
  "michael",
  "ashley",
  "qwerty",
  "111111",
  "iloveu",
  "000000",
  "michelle",
  "tigger",
  "sunshine",
  "chocolate",
  "password1",
  "soccer",
  "anthony",
  "friends",
  "butterfly",
  "purple",
  "angel",
  "jordan",
  "liverpool",
  "justin",
  "loveme",
  "fuckyou",
  "123123",
  "football",
  "secret",
  "andrea",
  "carlos",
  "jennifer",
  "joshua",
  "bubbles",
  "1234567890",
  "superman",
  "hannah",
  "amanda",
  "loveyou",
  "pretty",
  "basketball",
  "andrew",
  "angels",
  "tweety",
  "flower",
  "playboy",
  "hello",
  "elizabeth",
  "hottie",
  "tinkerbell",
  "charlie",
  "samantha",
  "barbie",
  "chelsea",
  "lovers",
  "teamo",
  "jasmine",
  "brandon",
  "666666",
  "shadow",
  "melissa",
  "eminem",
  "matthew",
  "robert",
  "danielle",
  "forever",
  "family",
  "jonathan",
  "987654321",
  "computer",
  "whatever",
  "dragon",
  "vanessa",
  "cookie",
  "naruto",
  "summer",
  "sweety",
  "spongebob",
  "joseph",
  "junior",
  "softball",
  "taylor",
  "yellow",
  "daniela",
  "lauren",
  "mickey",
  "princesa",
  "123456",
  "coucou",
  "123321",
  "azerty",
  "azertyuiop",
  "motdepasse",
  "chouchou",
  "jetaime",
  "159753",
  "maison",
  "aqwzsx",
  "123123123",
  "moimoi",
  "987654321",
  "789456",
  "aqwzsxedc",
  "monamour",
  "iloveyou",
];
