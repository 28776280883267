import React, { useMemo } from "react";

import Input from "./form/Input";

import { useForms } from "../stores/form";

export default function NbReunionDirigeantsParAn({ form, prefix, input, idx }) {
  const inputName = prefix + input.name;
  const { errors } = form;

  const entites = useForms(({ entites }) => entites);

  const entiteId = form.watch(prefix + "entiteId", null);
  const entite = useMemo(() => entites.find((e) => e.id === entiteId), [
    entites,
    entiteId,
  ]);

  const fonctionId = form.watch(prefix + "fonctionId", "autre");

  const membreIndependant = form.watch(prefix + "membreIndependant", false);

  if (
    entite?.denominationSociale === "BPCE" &&
    fonctionId === "president-du-conseil-de-surveillance" &&
    !membreIndependant
  ) {
    return (
      <Input
        key={`section-${idx}-input`}
        form={form}
        //ref={register(input.options)}
        type="number"
        name={inputName}
        label="Nombre de réunions de dirigeants par an"
        placeholder={input.placeholder}
        helpText={input.helpText}
        value={form.watch(inputName)}
        error={errors[inputName] && errors[inputName].message}
        // autoFocus={input.autoFocus}
        //disabled={disable.includes(input.name)}
        {...input.options}
      />
    );
  }

  return null;
}
