import React from 'react';

import logoBPCE from "assets/images/logo_groupe_bpce.png";

export default function PreLoginHeader() {
  return (
  <div className="pre-login-header">
    <h1 className="text-purple">
      Fit&nbsp;<span className="text-pink">&</span>&nbsp;Proper
    </h1>
    <img src={logoBPCE} alt="logo du groupe BPCE" />
  </div>
  )
}
