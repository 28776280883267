import React from "react";
import Api from "lib/api.js";
import { useUser } from "stores/user.js";

export default ({ file }) => {
  const { token } = useUser(({ token }) => ({ token }));
  const downloadFile = async () => {
    await Api.downloadFile(token, file.id, file.name);
  };
  return (
    <>
      <div
        className="cursor-pointer files-list-item-content"
        onClick={downloadFile}
      >
        <div className="files-list-item-content-item files-list-item-content-item-1">
          <span role="img" aria-label="">
            📁
          </span>
          {file.name}
        </div>
      </div>
    </>
  );
};
