import React, { useEffect, useState } from "react";

import { Controller, useWatch } from "react-hook-form";
import Select from "react-select";

import { useUser } from "stores/user";
import { useForms } from "stores/form";

export default ({
  required = false,
  form,
  // idx,
  prefix,
  setCurrentStepData,
  input: { name, label },
  When,
}) => {
  const { token } = useUser(({ token }) => ({ token }));
  const { listEntites } = useForms(({ listEntites }) => ({ listEntites }));

  const [entites, setEntites] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const run = async () => {
      const r = await listEntites(token);
      setEntites(r);
    };
    run();
  }, [listEntites, token]);

  useEffect(() => {
    const o = entites.map((e) => ({
      label: e.denominationSociale,
      value: e.id,
    }));

    setOptions([
      ...o,
      {
        label: "Autre Entité",
        value: null,
      },
    ]);
  }, [entites]);

  const currentValue = useWatch({
    control: form.control,
    name: prefix + name,
    defaultValue: form.getValues(prefix + name),
  });
  const [previousValue, setPreviousValue] = useState(
    form.getValues(prefix + name)
  );
  // const currentValue = form.watch(prefix + name, null);

  useEffect(() => {
    if (currentValue !== previousValue) {
      const values = form.getValues();
      const entite = entites.find((e) => e.id === currentValue);

      if (entite) {
        /* eslint-disable no-eval */
        eval(`values.${prefix}entiteNom = "${entite.denominationSociale}"`);

        setCurrentStepData(values);
      }
      setPreviousValue(currentValue);
    }
  }, [
    currentValue,
    entites,
    form,
    name,
    prefix,
    previousValue,
    setCurrentStepData,
  ]);

  return (
    <Controller
      control={form.control}
      rules={{ required: required ? "Ce champs est requis" : false }}
      name={prefix + name}
      render={({ onChange, value, name }) => {
        return (
          <div style={{ position: "relative", width: "100%" }}>
            <Select
              placeholder={"Entité"}
              isSearchable
              name={name}
              value={options.find((o) => o.value === value)}
              options={options}
              onChange={(entite) => {
                if (entite) {
                  onChange(entite.value);
                } else {
                  onChange(null);
                }
              }}
              label={label}
            />
            {When}
          </div>
        );
      }}
    />
  );
};
