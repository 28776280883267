import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { useNotify } from "stores/notify";

import useUsers from "hooks/useUsers";
import Register from "components/Register";
import PreLoginHeader from "components/SmsAccredHeader";
import { reportError } from "lib/error-report";

export default function RegisterPage({ history }) {
  const [finishMessage, setFinishMessage] = useState(null);
  const [email, setEmail] = useState(null);

  const params = new URL(document.location).searchParams;
  const token = params.get("token");

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }));

  const form = useForm();
  const { reset, handleSubmit } = form;

  const { checkRegistrationToken, registerAccreditation } = useUsers();

  const accreditationRequestSubmit = async (params) => {
    if (window.confirm("Envoyer la demande ?")) {
      try {
        await registerAccreditation({ ...params, token });
        setFinishMessage(
          "Merci d'avoir fait votre demande d'accréditation, vous serez avertis par mail de la réponse de votre demande."
        );
      } catch (err) {
        setErrorMessage(err.message);
      }
    }
  };

  useEffect(() => {
    const checkLinkValidity = async () => {
      try {
        const res = await checkRegistrationToken(token);
        const { results } = res;

        if (results && results.email) {
          setEmail(results.email);
        }
      } catch (err) {
        reportError(err);
        setErrorMessage(
          "Une erreur est survenue. Merci de refaire une demande."
        );
        history.replace("/request-access");
        // if no token, route throw error
      }
    };
    checkLinkValidity();
  }, [checkRegistrationToken, history, setErrorMessage, token]);

  useEffect(() => {
    reset({ email });
  }, [email, reset]);

  const onError = (e) => {
    reportError(e);
  };

  return (
    <div className="flex items-center justify-center mt-32 align-middle">
      <div className="page-container">
        <div className="w-full form-container">
          <PreLoginHeader />
          {finishMessage ? (
            <div className="w-full">{finishMessage}</div>
          ) : (
            <form
              onSubmit={handleSubmit(accreditationRequestSubmit, onError)}
              className="w-full"
            >
              <p>
                Veuillez remplir le formulaire de demande d’accréditation
                suivant. Un référent sera chargé de valider votre demande.
              </p>
              <Register form={form} />
              <div className="flex items-center justify-end w-full">
                <button className="action-button" type="submit">
                  Envoyer la demande d'accréditation
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
