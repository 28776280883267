/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useForm } from "react-hook-form";

import { useNotify } from "stores/notify";
import PreLoginHeader from "components/SmsAccredHeader";

const LoginComponent = ({ onSubmit }) => {
  const { register, handleSubmit, errors } = useForm();
  const { message } = useNotify(({ message }) => ({ message }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full form-container">
      <PreLoginHeader />
      <div className="flex flex-col w-full mb-4">
        <p className="text-red-500">
          {message && message.level === "error" && message.text}
        </p>
        <label htmlFor="username">
          <span>Adresse e-mail</span>
          <input
            type="email"
            placeholder="adresse@email.com"
            name="email"
            ref={register}
          />
        </label>
        {errors && errors.email && (
          <span className="text-red-600">{errors.email}</span>
        )}
      </div>
      <div className="flex flex-col w-full mb-6">
        <label htmlFor="password">
          <span>Mot de passe</span>
          <input
            type="password"
            placeholder="••••••"
            name="password"
            ref={register}
          />
        </label>
        {errors && errors.password && (
          <span className="text-red-600">{errors.password}</span>
        )}
      </div>
      <div className="flex items-center justify-between w-full">
        <a
          className="inline-block text-sm align-baseline text-purple hover:underline"
          href="#"
        >
          Mot de passe oublié&#x202F;?
        </a>
        <button className="action-button" type="submit">
          Se connecter
        </button>
      </div>
    </form>
  );
};

export default LoginComponent;
