import React from "react";
import SelectEntity from "components/form/EntiteSelect";
import Input from "components/form/Input";

export default function RegisterPage({ form }) {
  const { watch, register, errors } = form;

  return (
    <>
      <div className="mb-4">
        <label
          className="block mb-2 text-sm text-grey-darker"
          htmlFor="lastName"
        >
          Email
        </label>
        {watch("email")}
      </div>
      <Input
        form={form}
        key={`section-firstName`}
        ref={register({ required: "Champ obligatoire" })}
        type="text"
        name="lastName"
        label="Nom"
        placeholder="Nom"
        value={watch("firstName")}
        error={errors.firstName && errors.firstName.message}
        required={"Champ obligatoire"}
        autofocus={true}
      />

      <Input
        key={`section-lastName`}
        ref={register({ required: "Champ obligatoire" })}
        form={form}
        type="text"
        name="firstName"
        label="Prénom"
        placeholder="Prénom"
        value={watch("lastName")}
        error={errors.lastName && errors.lastName.message}
        required={"Champ obligatoire"}
        autofocus={true}
      />

      <div className="w-1/2 my-4">
        <SelectEntity
          required
          input={{ label: "Entité", name: "entiteId" }}
          form={form}
          key={`section-entite`}
          prefix={""}
          setCurrentStepData={(e) => {
            // console.log(e);
          }}
        />
      </div>
    </>
  );
}
