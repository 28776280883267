import create from "zustand";
import { immer, persist } from "state/middlewares";

import shallow from "lib/shallow";
import Api from "lib/api";
// user store
// TODO: move each store in its own module
const userStore = create(
  persist(
    "user",
    immer((set, _get) => ({
      token: null,
      error: null,
      loading: false,
      setToken: (token) => {
        set((state) => {
          state.token = token;
        });
      },
      login: async ({ email, password }) => {
        try {
          const { results } = await Api.signin({ email, password });
          set((_state) => ({
            ...results.user,
            error: null,
            token: results.token,
          }));
        } catch (err) {
          set((state) => {
            state.error = err;
          });
        }
      },

      setError: (error) => {
        set((state) => {
          state.error = error;
        });
      },
      getUserInfo: async (token) => {
        try {
          const { results } = await Api.getUserFromToken(token);

          if (results) {
            set((_state) => ({
              token: results.token,
              // candidature: results.candidature,
              // token: results.tokens.token,
              error: null,
            }));

            return results;
          } else {
            set((state) => {
              state.error = new Error("La création du compte a échoué");
            });
          }
        } catch (error) {
          console.warn(error);

          set((state) => {
            state.error = error;
          });
        }
      },
      checkValidationCode: async ({ code, candidatureToken, token }) => {
        try {
          const { results } = await Api.checkValidationCode({
            code,
            candidatureToken,
            token,
          });

          if (results && results.token) {
            set((_state) => ({
              candidature: results.candidature,
              token: results.token,
              error: null,
            }));
            return results;
          } else {
            set((state) => {
              state.error = new Error("Le code n'est pas valide");
            });
          }
        } catch (error) {
          set((state) => {
            state.error = error;
          });
          return { error };
        }
      },
      revokeMagicLink: async (token) => {
        try {
          await Api.revokeMagicLink(token);
        } catch (error) {
          set((state) => {
            state.error = error;
          });
        }
      },
      isUserVerified: async (token) => {
        try {
          const { results } = await Api.isUserVerified(token);
          return results.success;
        } catch (error) {
          set((state) => {
            state.error = error;
          });
        }
      },
      sendNewCode: async (token) => {
        try {
          const { results } = await Api.sendNewCode(token);
          return results.success;
        } catch (error) {
          set((state) => {
            state.error = error;
          });
        }
      },
    }))
  )
);

export const useUser = (slices) => shallow(userStore, slices);
export const userApi = userStore;
