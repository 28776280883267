import React from "react";

export default React.forwardRef(
  (
    {
      label,
      name,
      placeholder,
      required,
      onChange,
      className,
      helpText,
      error,
      value,
      rows,
    },
    ref
  ) => {
    let classes = className;

    if (required) {
      classes += " required";
    }

    label = label || "";

    return (
      <label className={classes} htmlFor={name}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        <textarea
          ref={ref}
          id={name}
          name={name}
          placeholder={(typeof placeholder !== "undefined"
            ? placeholder
            : label
          ).replace("&#x202F;", " ")}
          defaultValue={value}
          onChange={onChange}
          rows={rows ? rows : 2}
        ></textarea>
        {error ? (
          <span className="help-text error">{error}</span>
        ) : (
          <span className="help-text">{helpText}</span>
        )}
      </label>
    );
  }
);
