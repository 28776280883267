import create from "zustand";
import { immer } from "state/middlewares";
import shallow from "lib/shallow";

// user store
// TODO: move each store in its own module
const notifyStore = create(
  immer((set, get) => ({
    message: null,
    setMessage: (message) => {
      set((state) => {
        state.message = message;
      });
    },
    setInfoMessage: (text) => {
      set((state) => {
        state.message = { level: "info", text };
      });
    },
    setWarningMessage: (text) => {
      set((state) => {
        state.message = { level: "warning", text };
      });
    },
    setErrorMessage: (text) => {
      set((state) => {
        if (text) {
          state.message = { level: "error", text };
        } else {
          state.message = null;
        }
      });
    },
    setSuccessMessage: (text) => {
      set((state) => {
        state.message = { level: "success", text };
      });
    },
  }))
);

export const useNotify = (slices) => shallow(notifyStore, slices);
export const notifyApi = notifyStore;
