/* eslint-disable no-eval */
import React, { useEffect, useState } from "react";

import FormRow from "./FormRow";

const baseClasses = ["w-full"];

export default function Collapsable({
  name,
  collapsed,
  expanded,
  errors,
  idx,
  form,
}) {
  const [classes, setClasses] = useState(baseClasses);

  const prefix = name.endsWith(".") ? name.substring(0, name.length - 1) : name;

  let rendered = "";

  try {
    const item = eval(`formValues.${prefix}`);

    rendered = collapsed(item);
  } catch (error) {
    console.warn(error);
  }

  useEffect(() => {
    setClasses([...baseClasses, "collapsable"]);
  }, []);

  return (
    <div className={classes.join(" ")} tabIndex="0">
      <div className="w-full collapsed">{rendered}</div>
      <div className="expanded">
        {expanded.map((row, eidx) => (
          <FormRow
            key={`${idx}-${eidx}`}
            namePrefix={name.substring(0, name.length - 1)} // remove the "." at the end, added by parent FormRow
            inputs={row}
            idx={`${idx}-${eidx}`}
            form={form}
            errors={errors}
          />
        ))}
        setChecked()
      </div>
    </div>
  );
}
