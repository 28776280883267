import React, { useEffect, useState, useRef } from "react";
import * as State from "state";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";

import { useUser } from "stores/user";
import WelcomePage from "pages/Welcome";
import StepOnePage from "pages/Step1";
import SumupPage from "pages/Sumup";
import ThankYouGoodbyePage from "pages/ThankYouGoodbye";
import ErrorPage from "pages/Error";
import RegisterPage from "pages/Register";
import AcceptAccreditationPage from "pages/AcceptAccreditation";
import RequestAccessPage from "pages/RequestAccess";
import LoginPage from "pages/LoginPage";
import SetPasswordPage from "pages/SetPasswordPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import SmsValidationPage from "pages/SmsValidationPage";
import NoticeRGPD from "pages/NoticeRGPD";
import MentionsLegales from "pages/MentionsLegales";

import useQuery from "hooks/useQuery";

import { reportError, ErrorBoundary } from "lib/error-report";

State.initialize();

/**
 * Handle uncaught exceptions.
 * Sends a report to the API, which is responsible
 * for forwarting the report to the team
 *
 * @param {Event} _event
 * @param {string} _filename
 * @param {number} _line
 * @param {number} _column
 * @param {Error} error
 */
window.onerror = function (_event, _filename, _line, _column, error) {
  reportError(error);
};

/**
 * Handles unhandled rejections
 */
window.addEventListener("unhandledrejection", (event) => {
  let error = event.reason;

  if (!(error instanceof Error)) {
    error = new Error(JSON.stringify(error));
  }

  reportError(error);
});

const App = () => {
  let query = useQuery();

  return (
    <ErrorBoundary>
      <div className="app-container ">
        <Router>
          <Switch>
            <ProtectedRoute path="/welcome" component={WelcomePage} />
            <ProtectedRoute path="/etape/:step" component={StepOnePage} />

            <ProtectedRoute path="/sumup" component={SumupPage} />
            <ProtectedRoute path="/thank-you" component={ThankYouGoodbyePage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/set-password" component={SetPasswordPage} />
            <Route path="/sms-validation" component={SmsValidationPage} />
            <Route path="/reset-password" component={ResetPasswordPage} />
            <Route
              path="/accreditations/request-access"
              component={RequestAccessPage}
            />
            <Route path="/accreditations/register" component={RegisterPage} />
            <Route
              path="/accreditations/validate"
              component={AcceptAccreditationPage}
            />

            <InitialRoute path="/start/:uuid" />
            <Route exact path="/error">
              <ErrorPage errorMessage={query.get("msg")} />
            </Route>
            <Route path="/mentions-legales" component={MentionsLegales} />
            <Route path="/rgpd" component={NoticeRGPD} />
            <Route>
              {({ location }) => (
                <Redirect
                  to={`/error?msg=${encodeURIComponent(
                    `Page non trouvée${
                      location.path ? " : " + location.path : ""
                    }`
                  )}`}
                />
              )}
            </Route>
          </Switch>
        </Router>
      </div>
    </ErrorBoundary>
  );
};

const InitialRoute = ({ location }) => {
  const { pathname } = location;

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [userVerified, setUserVerified] = useState(false);

  const { isUserVerified, token, error, setError, getUserInfo } = useUser(
    ({ isUserVerified, token, error, setError, getUserInfo }) => ({
      token,
      error,
      setError,
      isUserVerified,
      getUserInfo,
    })
  );

  useEffect(() => {
    const checkVerifiedUser = async () => {
      if (token) {
        try {
          setIsLoading(true);
          const verified = await isUserVerified(token);

          if (mounted.current) {
            if (verified) {
              setUserVerified(true);
              setIsLinkValid(true);
            } else {
              setUserVerified(false);
            }
          }
        } catch (err) {
          reportError(err);
        } finally {
          mounted.current && setIsLoading(false);
        }
      }
    };
    checkVerifiedUser();
  }, [token, isUserVerified]);

  // Checks if the link is valid
  useEffect(() => {
    const getUserFromLink = async () => {
      setIsLoading(true);
      setError(null);

      if (!token) {
        try {
          await getUserInfo(pathname.split("/").pop());
          setIsLinkValid(true);
        } catch (err) {
          reportError(err);
        } finally {
          mounted.current && setIsLoading(false);
        }
      }
    };

    getUserFromLink();
  }, [getUserInfo, token, pathname, userVerified, history, setError]);

  useEffect(() => {
    if (
      error !== null &&
      (error instanceof Error || typeof error === "string")
    ) {
      history.push(
        `/error?msg=${encodeURIComponent(
          error.message || error || "Lien invalide"
        )}`
      );
    }
  }, [error, history]);

  if (isLoading || (!isLinkValid && !userVerified)) {
    return <div>Veuillez patienter</div>;
  }

  if (!isLoading && isLinkValid && userVerified) {
    history.replace("/welcome");
    return null;
  }

  if (isLinkValid && !userVerified) {
    return (
      <Redirect
        to={{
          pathname: "/sms-validation",
          state: { candidatureToken: pathname.split("/").pop() },
        }}
      />
    );
  }

  if (!isLoading && !isLinkValid) {
    return <Redirect to={`/error?msg="Lien%20invalide"`} />;
  }
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = useUser(({ token: t }) => t);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {token ? (
            <Component {...props} key={props.location.pathname} />
          ) : (
            <Redirect
              to={`/error?msg=${encodeURIComponent("Jeton invalide")}`}
            />
          )}
        </>
      )}
    />
  );
};

export default App;
