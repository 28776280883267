import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useUser } from "stores/user";
import { useNotify } from "stores/notify";

import useQuery from "hooks/useQuery";

import useUsers from "hooks/useUsers";

import Register from "components/Register";
import PreLoginHeader from "components/SmsAccredHeader";

import { reportError } from "lib/error-report";

export default function RegisterPage({ location }) {
  const history = useHistory();
  const params = useQuery();

  const [finishMessage, setFinishMessage] = useState(null);
  const [userToValidate, setUserToValidate] = useState({});

  const {
    acceptAccreditation,
    checkRegistrationToken,
    declineAccreditation,
  } = useUsers();
  const user = useUser((o) => o);

  const form = useForm();
  const { reset, handleSubmit } = form;

  const { message, setErrorMessage } = useNotify(
    ({ message, setErrorMessage }) => ({
      setErrorMessage,
      message,
    })
  );

  const q = new URLSearchParams({
    redirect: location.pathname + location.search,
  });

  if (!user.token && location.pathname.includes("accreditation")) {
    history.push(`/login?${q.toString()}`);
  }

  // Check user access.
  // Need have Référent role.
  useEffect(() => {
    if (user && user.role) {
      const isReferent = user.role === "superadmin";

      if (!isReferent) {
        setErrorMessage(
          "Vous n’avez pas les droits pour accepter une demande d’accréditation"
        );
        history.push(`/login?${q.toString()}`);
        return;
      }
    } else {
      setErrorMessage(
        "Vous n’avez pas les droits pour accepter une demande d’accréditation"
      );
      history.push(`/login?${q.toString()}`);
      return;
    }
  }, [history, q, setErrorMessage, user]);

  const token = params.get("token"); // is the string "Jonathan Smith".

  // Call API to check token.
  useEffect(() => {
    async function getUserByToken() {
      if (token) {
        try {
          const userByTokenResult = await checkRegistrationToken(token);

          if (userByTokenResult) {
            const userByToken = userByTokenResult.results;

            if (userByToken.BusinessLines && userByToken.BusinessLines.length) {
              userByToken.BusinessLineId = userByToken.BusinessLines[0].id;

              if (userByToken.BusinessLines[0].UserBusinessLine) {
                userByToken.role =
                  userByToken.BusinessLines[0].UserBusinessLine.role;
              }
            }

            setUserToValidate(userByToken);
          } else {
            history.push(
              `/error?msg=${encodeURIComponent(
                "Nous n’avons rien trouvé en relation avec ce jeton"
              )}`
            );
          }
        } catch (err) {
          reportError(err);
          history.push(
            `/error?msg=${encodeURIComponent("Jeton invalide ou expiré")}`
          );
        }
      }
    }

    getUserByToken();
  }, [checkRegistrationToken, history, setErrorMessage, token]);

  useEffect(() => {
    reset(userToValidate);
  }, [reset, userToValidate]);

  const declineAccreditationSubmit = async () => {
    if (window.confirm("Êtes-vous sûr de refuser la demande ?")) {
      try {
        await declineAccreditation(token);
        setFinishMessage(
          "La demande a été refusée, la personne concernée recevra un mail de notification."
        );
      } catch (err) {
        setErrorMessage(err);
      }
    }
  };

  // const { register, handleSubmit, errors } = useForm()
  const acceptAccreditationSubmit = async (data) => {
    if (window.confirm("Êtes-vous sûr de confirmer la demande ?")) {
      try {
        await acceptAccreditation({ token, ...data });
        setFinishMessage(
          "La demande a été acceptée, la personne concernée recevra un mail avec un lien de connexion unique."
        );
      } catch (err) {
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <div className="flex items-center justify-center mt-32 align-middle">
      <div className="page-container">
        <div className="w-full form-container">
          <PreLoginHeader />
          {finishMessage ? (
            <div className="w-full">{finishMessage}</div>
          ) : (
            <form
              onSubmit={handleSubmit(acceptAccreditationSubmit)}
              className="w-full"
            >
              {message ? (
                message.level === "error" && (
                  <div className="text-red-600">{message.text}</div>
                )
              ) : (
                <>
                  <div className="flex flex-col items-center justify-between">
                    <p className="mb-4">
                      Accepter la demande a pour effet de générer un lien de
                      connexion à la personne faisant le demande
                      d'accréditation. Cette personne pourra ainsi se connecter
                      au panneau d'administration.
                    </p>
                  </div>

                  <Register form={form} />
                  <div className="flex items-center justify-end w-full">
                    <button
                      className="mr-2 action-button danger"
                      onClick={() => declineAccreditationSubmit()}
                      type="button"
                    >
                      Refuser la demande
                    </button>
                    <button className="action-button" type="submit">
                      Accepter la demande
                    </button>
                  </div>
                </>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
