import React from "react";
import Select from "react-select";

export default React.forwardRef(
  (
    {
      label,
      name,
      placeholder,
      required,
      onChange,
      className,
      helpText,
      error,
      value,
      values,
      options,
      when,
    },
    ref
  ) => {
    let classes = className;
    options = options || {};

    if (required) {
      classes += " required";
    }

    if (typeof value === "string") {
      try {
        value = JSON.parse(value).map((x) => values.find((v) => v.value === x));
      } catch (err) {
        value = values.find((v) => v.value === value);
      }
    }

    let displayWhen = options.isMulti
      ? when &&
        value &&
        value.find((v) => v && when[v.value]) &&
        when[value.find((v) => v && when[v.value]).value]
      : when && value && value.value && when[value.value];

    if (options.isMulti && Array.isArray(value)) {
      value = value.map((v) => {
        if (typeof v === "string") {
          return values.find((m) => m.value === v);
        }

        return v;
      });
    }

    return (
      <label className={classes} htmlFor={name}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        <Select
          name={name}
          value={value}
          options={values}
          isMulti={options.isMulti}
          label={label}
          onChange={(e) => {
            if (e === null) return onChange(null);

            return options.isMulti
              ? onChange(JSON.stringify(e.map((e) => e.value)))
              : onChange(possiblyRecursive(e, "value", "string"));
          }}
          isClearable
          required={!!options && !!options.required}
        />
        {error ? (
          <span className="help-text error">{error}</span>
        ) : (
          <span className="help-text">{helpText}</span>
        )}
        {displayWhen && <div className="complement-input">{displayWhen}</div>}
      </label>
    );
  }
);

/**
 * PROBABLY A HACK
 * Seems like Select.onChange event parameter as a `value` property
 * that can be either a primitive or an object with a `value` propery
 * that can be either a primitive or an object with a `value` propery
 * that can be either a primitive or an object with a `value` propery
 * and so on...
 *
 * This function aims at always returning the primitive value
 * no matter how deep it might be nested
 */
function possiblyRecursive(object, property, type = "string") {
  if (typeof object !== "object") return object;
  if (object === null) return object;
  if (!Object.hasOwnProperty.call(object, property)) return undefined;

  if (typeof object[property] === type) {
    return object[property];
  }

  return possiblyRecursive(object[property], property, type);
}
