import React, { useEffect } from "react";
import { useUser } from "stores/user";
import { useForms } from "stores/form";

import Footer from "components/Footer";

export default function ThankYouGoodbye() {
  const { token, candidature, revokeMagicLink } = useUser(
    ({ token, candidature, revokeMagicLink }) => ({
      token,
      candidature,
      revokeMagicLink,
    })
  );

  const {
    id,
    entite: { contacts },
  } = candidature;

  const { saveFormToDb, finishCandidature } = useForms(
    ({ saveFormToDb, finishCandidature }) => ({
      saveFormToDb,
      finishCandidature,
    })
  );

  useEffect(() => {
    (async () => {
      if (token) {
        await saveFormToDb(token);
        await finishCandidature(token, id);
        await revokeMagicLink(token);
      }
    })();
  }, [revokeMagicLink, saveFormToDb, token, id, finishCandidature]);

  return (
    <div className="flex flex-col w-full" style={{ minHeight: "100vh" }}>
      <div className="flex-grow page-container" style={{ display: "block" }}>
        <div className="welcome-card">
          <div className="welcome-card-right">
            <h2>Merci d’avoir complété votre formulaire</h2>
          </div>
          <div className="px-4 thank-you-text">
            <p>
              Nous vous remercions pour vos réponses et vous confirmons leur
              envoi aux équipes Fit & Proper en charge de finaliser votre
              dossier.
            </p>
            {contacts && contacts.length > 0 && (
              <p>
                En cas de besoin, vous pouvez contacter&nbsp;:
                <ul>
                  {contacts.map((contact) => (
                    <li>
                      {contact.firstName} {contact.lastName}, au{" "}
                      {contact.telephone} ou par email {contact.email}
                      <br />
                      Fonction&nbsp;: {contact.fonction}
                    </li>
                  ))}
                </ul>
              </p>
            )}
          </div>
        </div>
      </div>
      {candidature && <Footer entite={candidature.entite} />}
    </div>
  );
}
