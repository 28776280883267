import React from "react";
import Input from "./form/Input";

const DIRIGEANT = ["president-directeur-general", "president-du-directoire"];

export default function NbJoursReunionsDirigeantsParAn(props) {
  const { form, prefix, input, idx } = props;
  const inputName = prefix + input.name;
  const { errors } = form;

  const fonctionId = form.watch(prefix + "fonctionId", "autre");

  if (DIRIGEANT.includes(fonctionId)) {
    return (
      <Input
        key={`section-${idx}-input`}
        form={form}
        type="number"
        name={inputName}
        label="Temps consacré aux réunions de dirigeants par an"
        placeholder={input.placeholder}
        helpText={input.helpText}
        value={form.watch(inputName)}
        error={errors[inputName] && errors[inputName].message}
        {...input.options}
      />
    );
  }

  return null;
}
