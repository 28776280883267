import React, { useState, useEffect, useCallback } from "react";
import { useNotify } from "stores/notify";
import { useUser } from "stores/user";
import useUsers from "hooks/useUsers";
import ResetPassword from "components/ResetPassword";

import PreLoginHeader from "components/SmsAccredHeader";

export default function SetPasswordPage({ history }) {
  const { checkLoginToken, setPassword } = useUsers();
  const { setUser } = useUser((o) => o);
  const [finishMessage, setFinishMessage] = useState(null);

  const params = new URL(document.location).searchParams;
  const token = params.get("token");

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }));

  const onSubmit = useCallback(
    async (params) => {
      try {
        const res = await setPassword({ ...params, token });

        if (res && res.results && res.results.tokens) {
          setUser(res.results);
        }
        setFinishMessage(
          "Votre mot de passe a été enregistré. Vous pouvez vous connecter à la plateforme d’administration Fit&nbsp;&&nbsp;Proper."
        );
      } catch (err) {
        setErrorMessage(err.message);
      }
    },
    [setErrorMessage, setPassword, setUser, token]
  );

  useEffect(() => {
    const checkUserToken = async () => {
      try {
        await checkLoginToken(token);
      } catch (err) {
        setErrorMessage("Le jeton a expiré.");
        history.replace("/request-access");
        // if no token, route throw error
      }
    };
    checkUserToken();
  }, [checkLoginToken, history, setErrorMessage, token]);

  return (
    <div className="flex items-center justify-center mt-32 align-middle">
      <div className="page-container">
        <div className="w-full form-container">
          <PreLoginHeader />
          {finishMessage ? (
            <div
              className="w-full"
              dangerouslySetInnerHTML={{ __html: finishMessage }}
            />
          ) : (
            <ResetPassword onSubmit={onSubmit} />
          )}
        </div>
      </div>
    </div>
  );
}
