import React, { useMemo } from "react";

/**
 * Displays the total of time dedicated to all the candidates positions
 * @param {Object} props
 * @param {FormState} props.form
 * @param {{ label: string }} props.input
 * @returns {string}
 */
export default function TotalTempsConsacre({ form, input: { label } }) {
  const mandatFonctions = form.watch("mandatFonction", []);
  const overrideJoursParAn = form.watch("overrideJoursParAn", false);
  const totalNbJoursParAnManuel = form.watch("totalNbJoursParAnManuel", NaN);

  // Total time dedicated, if an override has been set, use the manual values
  // otherwise computes the values summing the mandatFonctions computed time,
  // which may be overriden individally as well
  const totalTempsConsacre = useMemo(() => {
    let tempsConsacre = NaN;

    if (overrideJoursParAn === "yes") {
      tempsConsacre = totalNbJoursParAnManuel;
    } else {
      tempsConsacre = mandatFonctions.reduce(
        (acc, { tempsJoursAnnees }) =>
          parseFloat(acc, 10) + parseFloat(tempsJoursAnnees || 0, 10),
        0
      );
    }

    if (isNaN(tempsConsacre)) {
      return "N/A";
    }

    return tempsConsacre;
  }, [mandatFonctions, overrideJoursParAn, totalNbJoursParAnManuel]);

  // Error display
  const error = useMemo(() => {
    if (typeof totalTempsConsacre === "string") {
      return {
        severity: "error",
        message:
          "Les infomations fournies sont insuffidantes pour calculer le temps total consacré à vos fonctions",
      };
    }

    if (totalTempsConsacre >= 250 && totalTempsConsacre < 260) {
      return {
        severity: "warning",
        message:
          "Attention&nbsp;: le nombre total de jours par an est supérieur à 250. La personne devra vérifier que le temps consacré aux fonctions n’est pas trop élevé.",
      };
    } else if (totalTempsConsacre >= 260) {
      return {
        severity: "error",
        message: `Le nombre total de jours par an (actuellement ${totalTempsConsacre}) ne peut pas dépasser les 260 jours.`,
      };
    }

    return null;
  }, [totalTempsConsacre]);

  return (
    <label>
      <div dangerouslySetInnerHTML={{ __html: label }} />
      <div className="text-xl">
        <strong>{totalTempsConsacre}</strong> jours par an
      </div>
      {error && (
        <div className={`severity-${error.severity}`}>
          <span dangerouslySetInnerHTML={{ __html: error.message }} />
        </div>
      )}
    </label>
  );
}
