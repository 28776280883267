import React, { useRef, useEffect } from "react";

import { Controller } from "react-hook-form";

export default ({
  form,
  type,
  label,
  name,
  placeholder,
  required,
  className,
  helpText,
  error,
  value,
  minLength,
  maxLength,
  step,
  autoFocus,
  disabled,
  rules,
}) => {
  let classes = className || "";

  if (required) {
    classes += " required";
  }

  if (type === "hidden") {
    classes += " hidden";
  }

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <label className={classes} htmlFor={name}>
      <span dangerouslySetInnerHTML={{ __html: label }} />
      <Controller
        control={form.control}
        name={name}
        defaultValue={value || ""}
        rules={rules}
        render={({ name, value, onFocus, onBlur, onChange }) => (
          <input
            ref={(r) => {
              // ref(r);
              inputRef.current = r;
            }}
            type={type}
            id={name}
            name={name}
            placeholder={
              typeof placeholder !== "undefined" ? placeholder : label
            }
            defaultValue={value}
            onChange={(e) => {
              if (type === "number") {
                e.target.value = parseFloat(e.target.value, 10);
              }
              onChange(e);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            // min={min}
            // max={max && max.value ? max.value : max}
            step={step}
            minLength={minLength}
            maxLength={maxLength}
            disabled={disabled}
          />
        )}
      />
      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{helpText}</span>
      )}
    </label>
  );
};
