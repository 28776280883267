import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useNotify } from "stores/notify";
import { useUser } from "stores/user";
import useOnMount from "hooks/useOnMount";
import useQuery from "hooks/useQuery";

import Login from "components/Login";

function tryJSONParseError(input) {
  let message = input;

  try {
    message = JSON.parse(input).message;
  } catch (_) {
    //
  }

  return message;
}

const LoginPage = () => {
  const history = useHistory();
  const query = useQuery();

  const { login, error } = useUser(({ login, error }) => ({
    login,
    error,
  }));
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }));

  if (error && error.message) setErrorMessage(tryJSONParseError(error.message));

  useOnMount(() => {
    setErrorMessage(null);
  });

  const submit = useCallback(
    async (params) => {
      setErrorMessage(null);
      await login(params);

      const redirect = query.get("redirect");

      if (redirect) {
        history.push(redirect);
      } else {
        history.goBack();
      }
    },
    [history, login, query, setErrorMessage]
  );

  return (
    <div className="flex items-center justify-center mt-32 align-middle">
      <div className="page-container login">
        <Login onSubmit={submit} />
      </div>
    </div>
  );
};

export default LoginPage;
