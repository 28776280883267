import React, { useMemo } from "react";
import { Controller } from "react-hook-form";

import { useForms } from "../stores/form";

import FormRow from "./form/FormRow";
import Select from "./form/Select";
import CheckBox from "./form/CheckBox";

import { FONCTIONS_CHOICES, MANDATS_CHOICES } from "../lib/fonctionNames";

const INDEX_REGEX = /\[(\d+)\]\.$/;

function getIndex(prefix) {
  const match = INDEX_REGEX.exec(prefix);

  if (match && Array.isArray(match)) {
    // eslint-disable-next-line
    const [_, group] = match;

    const index = parseInt(group, 10);

    if (!isNaN(index)) {
      return index;
    }
  }

  throw new Error(`Invalid index: ${prefix}`);
}

export default function FonctionSelector({ form, prefix, input, idx }) {
  const index = getIndex(prefix);
  const inputName = prefix + input.name;
  const iidx = "fonction-selector";

  const entites = useForms(({ entites }) => entites);

  const entiteId = form.watch(prefix + "entiteId", null);
  const entite = useMemo(() => entites.find((e) => e.id === entiteId), [
    entiteId,
    entites,
  ]);

  const fonctionId = form.watch(inputName, "autre");

  const { errors, control } = form;

  const fonctions_choices = index === 0 ? FONCTIONS_CHOICES : MANDATS_CHOICES;

  return (
    <>
      <Controller
        name={inputName}
        control={control}
        rules={input.options}
        render={(props) => {
          return (
            <Select
              {...input}
              {...props}
              values={fonctions_choices}
              onChange={(value) => props.onChange(value)}
              name={inputName}
              error={errors[inputName] && errors[inputName].messge}
              when={
                input.when &&
                Object.keys(input.when).reduce((acc, whenKey, ynridx) => {
                  acc[whenKey] = input.when[whenKey].map((row) => (
                    <FormRow
                      inputs={row}
                      namePrefix={prefix}
                      idx={`${idx}-${iidx}-${whenKey}`}
                      key={`${idx}-${iidx}-${whenKey}`}
                      ridx={ynridx}
                      form={form}
                    />
                  ));

                  return acc;
                }, {})
              }
            />
          );
        }}
      />
      {entite?.denominationSociale === "BPCE" &&
      fonctionId === "membre-du-conseil-de-surveillance" ? (
        <div className="flex flex-col justify-center w-full mt-4">
          <CheckBox
            label="Membre indépendant"
            name={prefix + "membreIndependant"}
            form={form}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full my-10 lg:my-4" />
      )}
    </>
  );
}
