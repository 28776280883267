import React from "react";
import { useForm } from "react-hook-form";
import { useUser } from "stores/user";
import passwords from "lib/passwords";
import Input from "components/form/Input";

const validatePassword = (value) => {
  const validation1 =
    [
      "^(?=.*[a-z]).+$",
      "^(?=.*[A-Z]).+$",
      "^(?=.*\\d).+$",
      "^(?=.*[!@#$%^&*()_+\\-=\\[\\]{};':\"\\|,.<>\\/?]).+$",
    ]
      .map((re) => !!value.match(new RegExp(re)))
      .filter((v) => v).length >= 3;

  const validation2 = value.length >= 8;

  const validation3 = !passwords.find((p) =>
    value.toLowerCase().includes(p.toLowerCase())
  );
  return (validation1 && validation2 && validation3) || ` `;
};

export default function ResetPassword({ onSubmit }) {
  const form = useForm();
  const { error } = useUser(({ error }) => ({
    error,
  }));
  const { errors, handleSubmit, watch } = form;

  function validatePasswordComfirmation(value) {
    return watch("password") === value || " ";
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <p>
        Veuillez définir ci-dessous votre mot de passe d’accès à la plateforme
        Fit&nbsp;&&nbsp;Proper&nbsp;:
      </p>
      <Input
        form={form}
        key={`section-password`}
        rules={{ required: "requis", validate: validatePassword }}
        type="password"
        name="password"
        label="Mot de passe"
        value={watch("password")}
        error={errors.password && errors.password.message}
        required={"Champ obligatoire"}
        autofocus={true}
      />
      <span className="text-sm text-red-500">
        {errors && errors.password && (
          <ul>
            <li>
              • le mot de passe doit avoir une longueur minimale de 8
              caractères;
            </li>
            <li>
              • le mot de passe doit être composé avec une combinaison d’au
              moins 3 types de caractères parmi les 4 suivants&nbsp;: majuscules
              (A-Z), minuscules (a-z), chiffres (0-9) et caractères spéciaux;
            </li>
            <li>
              • le mot de passe ne doit pas être constitué d’une combinaison
              d’un ou plusieurs mots communs et de chiffres.
            </li>
          </ul>
        )}
      </span>
      <Input
        form={form}
        key={`section-confirmation-password`}
        rules={{
          required: "requis",
          validate: validatePasswordComfirmation,
        }}
        type="password"
        name="passwordComfirmation"
        label="Confirmation du mot de passe"
        value={watch("passwordComfirmation")}
        error={
          errors.passwordComfirmation && errors.passwordComfirmation.message
        }
        required={"Champ obligatoire"}
        autofocus={true}
      />
      {errors.passwordComfirmation && errors.passwordComfirmation.message && (
        <span className="text-sm text-red-500">
          Les mots de passes ne correspondent pas.
        </span>
      )}
      {error && <span className="text-sm text-red-500">{error}</span>}
      <div className="flex items-center justify-end w-ull">
        <button className="action-button" type="submit">
          Enregistrer
        </button>
      </div>
    </form>
  );
}
