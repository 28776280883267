import React, { useEffect, useState } from "react";

import { Controller } from "react-hook-form";

import DatePicker, { registerLocale } from "react-datepicker";
import { format, isAfter, isBefore } from "date-fns";
import fr from "date-fns/locale/fr";

import "react-datepicker/dist/react-datepicker.css";
registerLocale("fr", fr);

export default function MandatDateDeFin({
  className,
  input,
  prefix,
  error,
  required,
  form,
}) {
  let classes = className || "flex flex-col";
  let { name, label, helpText, options } = input;
  name = prefix + name;

  if (required || options?.required) {
    classes += " required";
  }

  const clearable = !(required || options?.required);

  const [prev, setPrev] = useState(false);

  const dureeIndeterminee = form.watch(prefix + "dureeIndeterminee", false);
  const min =
    options.min === "today"
      ? new Date()
      : new Date(options.min.value || options.min) || "1901-01-01";

  const dateDebut = form.watch(prefix + "dateDebut", min);

  useEffect(() => {
    if (dureeIndeterminee && dureeIndeterminee !== prev) {
      setPrev(dureeIndeterminee);
    }
  }, [dureeIndeterminee, form, name, prev]);

  return (
    <Controller
      control={form.control}
      name={name}
      rules={{
        validate: {
          required: (value) => {
            if (options.required) {
              if (!!value) {
                return true;
              }

              return options.required;
            }
          },
          min: (value) => {
            const date = new Date(value);

            let min =
              options.min?.value ?? (options.min || "1901-01-01T00:00:00.000Z");
            min = min === "today" ? new Date() : new Date(min);
            const message =
              options.min?.message ||
              `La date doit être postérieure au ${format(min, "dd/MM/yyyy")}`;

            if (isAfter(date, min)) {
              return true;
            }

            const result = !isAfter(date, min) && message;
            return result || undefined;
          },
          max: (value) => {
            const date = new Date(value);

            let max =
              options.max?.value ?? options.max ?? "2999-12-31T12:59:59.999Z";
            max = max === "today" ? new Date() : new Date(max);
            const message =
              options.max?.message ||
              `La date doit être antérieure au ${format(max, "dd/MM/yyyy")}`;

            if (isBefore(date, max)) {
              return true;
            }

            const result = !isBefore(date, max) && message;
            return result || undefined;
          },
        },
      }}
      render={({ onChange, onBlur, value, name }) => {
        let selected = new Date();

        try {
          selected = new Date(value);

          if (isNaN(selected)) {
            onChange(new Date().toISOString());
            return null;
          }
        } catch (e) {
          // ignore
        }

        if (dureeIndeterminee) {
          let maxedOutDate = new Date("2999-12-30T23:59:59.999").toISOString();
          if (maxedOutDate !== value) {
            onChange(maxedOutDate);
            return null;
          }
        }

        return (
          <label className={classes}>
            <span>{label}</span>
            <DatePicker
              locale={fr}
              name={name}
              selected={selected}
              onChange={(date) => date && onChange(date.toISOString())}
              onBlur={onBlur}
              firstDayOfWeek={1}
              dateFormat="dd/MM/yyyy"
              isClearable={clearable}
              disabled={dureeIndeterminee}
              minDate={+min > +dateDebut ? min : dateDebut}
              maxDate={new Date("2999-12-31T12:59:59.999Z")}
            />
            {error ? (
              <span className="help-text error">{error}</span>
            ) : (
              <span className="help-text">{helpText}</span>
            )}
          </label>
        );
      }}
    />
  );
}
