import React from "react";
import { Link } from "react-router-dom";

export default function Footer({ entite, style }) {
  if (entite && entite.contacts) {
    return (
      <div className="bottom-bar" style={style}>
        <div>
          <p>
            Pour toute information complémentaire concernant votre candidature
            Fit & Proper, vous pouvez contacter les personnes suivantes&nbsp;:
          </p>
          <ul>
            {entite.contacts.map((contact) => (
              <li className="contact-card" key={`contact-${contact.id}`}>
                <div className="contact-name">
                  <span>{contact.firstName}</span>&nbsp;
                  <span>{contact.lastName}</span>
                </div>
                <div className="contact-fonction">
                  <span>{contact.fonction}</span>&nbsp;à&nbsp;
                  <span>{entite.denominationSociale}</span>
                </div>
                <div className="contact-links">
                  <span>{contact.email}</span>&nbsp;-&nbsp;
                  <span>{contact.telephone}</span>
                </div>
              </li>
            ))}
          </ul>
          <p>
            <Link to="/mentions-legales">Mentions Légales</Link>
            <Link to="/rgpd">Notice RGPD</Link>
          </p>
        </div>
      </div>
    );
  }
  return <div></div>;
}
