import { useMemo } from "react";
import { useUser } from "stores/user";
import Api from "lib/api";

export default () => {
  const { token } = useUser((o) => o);

  return useMemo(
    () => ({
      declineAccreditation(userAccreditationtoken) {
        return Api.declineAccreditation(token, userAccreditationtoken);
      },
      acceptAccreditation(params) {
        return Api.acceptAccreditation(token, params);
      },

      checkRegistrationToken(token) {
        return Api.checkRegistrationToken(token);
      },

      registerAccreditation(params) {
        return Api.registerAccreditation(params);
      },

      requestAccess(email) {
        return Api.requestAccess(email);
      },
      checkLoginToken(token) {
        return Api.checkLoginToken(token);
      },
      setPassword({ token, password }) {
        return Api.setPassword({ token, password });
      },
      resetPassword({ token, password }) {
        return Api.resetPassword({ token, password });
      },
    }),
    [token]
  );
};
