import React, { useEffect, useState } from "react";
import { useUser } from "stores/user";
import useUsers from "hooks/useUsers";
import ResetPassword from "components/ResetPassword";

import PreLoginHeader from "components/SmsAccredHeader";
export default function ResetPasswordPage({ history }) {
  const { resetPassword } = useUsers();
  const { setError } = useUser(({ setError }) => ({
    setError,
  }));
  const [finishMessage, setFinishMessage] = useState(null);

  const params = new URL(document.location).searchParams;
  const token = params.get("token");
  useEffect(() => {
    setError(null);
  }, [setError]);

  const onSubmit = async (params) => {
    try {
      const res = await resetPassword({ ...params, token });

      if (res && res.results)
        setFinishMessage(
          "Votre mot de passe a été enregistré. Vous pouvez vous connecter au panneau d'administration Fit & Proper."
        );
      else setError(res);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex items-center justify-center mt-32 align-middle">
      <div className="page-container">
        <div className="w-full form-container">
          <PreLoginHeader />
          <div className="w-full">
            {finishMessage ? (
              <span>
                {finishMessage}&nbsp;
                <a href={process.env.REACT_APP_ADMIN_URL}>C’est par ici</a>
              </span>
            ) : (
              <ResetPassword onSubmit={onSubmit} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
