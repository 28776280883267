import React, { useEffect } from "react";

import { Controller } from "react-hook-form";

export default function YesNoInput({
  input,
  name,
  yes,
  no,
  form,
  error,
  whenYes,
  whenNo,
  register,
  value,
  setValue,
}) {
  useEffect(() => {
    if (input.whenYes && value === "no") {
      input.whenYes.map((row) =>
        row.map((input) => input.name && setValue(input.name, ""))
      );
    }
    if (input.whenNo && value === "yes") {
      input.whenNo.map((row) =>
        row.map((input) => input.name && setValue(input.name, ""))
      );
    }
  }, [setValue, value, input]);

  return (
    <div className="flex flex-col w-full my-10 lg:my-4">
      <div className="flex flex-col items-center justify-between w-full text-sm lg:flex-row">
        <span
          dangerouslySetInnerHTML={{ __html: input.label }}
          style={{ maxWidth: "90%" }}
        />
        <div className="flex flex-row">
          <Controller
            control={form.control}
            name={name}
            defaultValue={value || "no"}
            render={({ onChange, value, name }) => {
              return (
                <>
                  <label htmlFor={`yes-${name}`} className="radio-label">
                    <input
                      //ref={register()}
                      type="radio"
                      id={`yes-${name}`}
                      name={name}
                      value="yes"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      checked={value === "yes"}
                    />
                    <span>{yes || "Oui"}</span>
                  </label>
                  <label htmlFor={`no-${name}`} className="radio-label">
                    <input
                      //ref={register()}
                      type="radio"
                      id={`no-${name}`}
                      name={name}
                      value="no"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      checked={value === "no"}
                    />
                    <span>{no || "Non"}</span>
                  </label>
                </>
              );
            }}
          />
        </div>
      </div>
      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{input.helpText}</span>
      )}
      {value === "yes" && <div className="complement-input">{whenYes}</div>}
      {value === "no" && <div className="complement-input">{whenNo}</div>}
    </div>
  );
}
