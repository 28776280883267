import React from "react";

import { useForms } from "stores/form";
import { useUser } from "stores/user";
import { useHistory, Link } from "react-router-dom";

import FormDefinitions from "../forms";

import logoBPCE from "assets/images/logo_groupe_bpce.png";
import Footer from "components/Footer";

const progress = 100;

export default function Sumup() {
  const { candidature } = useUser(({ candidature }) => ({ candidature }));
  const { getErrors } = useForms(({ getErrors }) => ({ getErrors }));
  const history = useHistory();

  const errors = getErrors();

  if (Object.keys(errors).length === 0) {
    history.push("/thank-you");
  }

  return (
    <div
      className="relative flex flex-col w-full"
      style={{ minHeight: "100vh" }}
    >
      <div className="top-bar">
        <h1 className="font-serif text-xl uppercase text-purple">
          Fit&nbsp;<span className="text-pink">&</span>&nbsp;Proper
        </h1>
        <div className="separator" />
        <img src={logoBPCE} alt="logo du groupe BPCE" />
      </div>
      <div
        className="flex flex-col items-start flex-grow page-container"
        style={{ justifyContent: "flex-start" }}
      >
        <div className="progress">
          <span>Taux d’avancement: {progress}%</span>

          <span className="progress-container">
            <span className="progress-bar" style={{ width: `${progress}%` }} />
          </span>
        </div>

        <form className="form-container">
          <h2>Certaines étapes sont incomplètes</h2>

          <div className="form-content">
            <span>
              Merci de remplir tous les champs indiqués comme requis (avec le
              symbole *).
              <br />
              Vous ne pourrez finaliser la complétion de votre dossier que
              lorsque toutes les étapes seront complètes.
            </span>

            {Object.entries(errors).map(([formName, _]) => {
              const Form = FormDefinitions[formName];

              return (
                <Link
                  to={formName.replace("-", "/")}
                  className="block incomplete-form-title"
                >
                  {Form.title}
                </Link>
              );
            })}
          </div>
        </form>
      </div>
      {candidature && <Footer entite={candidature.entite} />}
    </div>
  );
}
