import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import logoBPCE from "assets/images/logo_groupe_bpce.png";

export default function NoticeRGPD(props) {
  const history = useHistory();
  const location = useLocation();

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div key={location.pathname} className="w-full rgpd">
      <div className="top-bar">
        <h1 className="font-serif text-xl uppercase text-purple">
          Fit&nbsp;<span className="text-pink">&</span>&nbsp;Proper
        </h1>
        <div className="hidden lg:visibles separator" />
        <img
          src={logoBPCE}
          className="hidden lg:block"
          alt="logo du groupe BPCE"
        />
      </div>

      <div className="page-container">
        <div className="form-container">
          <h2>Notice d’information</h2>

          <div className="form-content">
            <p>
              BPCE recueille des données à caractère personnel vous concernant
              et met en œuvre des mesures techniques et organisationnelles
              appropriées pour s’assurer que les traitements de données à
              caractère personnel sont effectués conformément à la législation
              applicable.
              <br />
              Les données recueillies par l’intermédiaire du site{" "}
              <strong>
                «&nbsp;Plateforme Fit&nbsp;&&nbsp;Proper Groupe&nbsp;»
              </strong>{" "}
              sont obligatoires. À défaut votre candidature pourrait ne pas être
              retenue ou son traitement s’en trouverait retardé.
              <br />
              Vos données sont traitées pour les finalités suivantes&nbsp;:
            </p>
            <p>
              <strong>Finalité(s)&nbsp;:</strong> Complétude du formulaire
              relatif à l’expertise et à l’honorabilité dans le cadre d’une
              candidature ou d’un renouvellement à un poste de dirigeant
              effectif ou mandataire social (Fit&nbsp;and&nbsp;Proper)
            </p>
            <p>
              <strong>Fondement légal du traitement&nbsp;:</strong> Obligation
              légale, Décret n° 2014-1357 du 13 novembre 2014
            </p>
            <p>
              <strong>Destinataire(s)&nbsp;:</strong>
              Vos données sont destinées à BPCE SA, responsable de traitement, à
              l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), à la
              Banque Centrale Européenne (BCE)
            </p>
            <p>
              <strong>Durée de conservation&nbsp;:</strong> La durée de
              conservation des données est de dix (10) ans à compter du dernier
              poste postulé ou occupé
            </p>
            <p>
              <strong>Exercice des droits&nbsp;:</strong> Vous bénéficiez d’un
              droit d’accès à vos données à caractère personnel. Dans les
              conditions prévues par Règlement Général relatif à la Protection
              des Données (RGPD), vous pouvez également demander une limitation
              du traitement, la rectification ou l’effacement des données vous
              concernant, ainsi que leur portabilité, ou communiquer des
              directives sur le sort de ces données en cas de décès. Ces droits
              peuvent, sous réserve de justifier de votre identité par la
              production d’une copie d’une pièce d’identité, être exercés à tout
              moment à l’adresse suivante&nbsp;:
            </p>
            <p>
              Par courriel&nbsp;:{" "}
              <a href="mailto:delegue-protection-donnees@bpce.fr">
                delegue-protection-donnees@bpce.fr
              </a>
            </p>
            <p>
              Par courrier postal&nbsp;: BPCE/Fit and Proper/à l’attention du
              Délégué à la protection des données, 50 avenue Pierre
              MENDES-France 75013 PARIS
            </p>
            <p>
              <strong>Réclamations&nbsp;:</strong> Les personnes concernées ont
              le droit d’introduire une réclamation auprès d’une autorité de
              contrôle en charge de la protection des personnes physiques à
              l’égard du traitement des données à caractèrepersonnel. En France,
              l’autorité de contrôle est&nbsp;: Commission Nationale de
              l’Informatique et des Libertés (CNIL), 3 place de Fontenoy TSA
              80715 - 75334 PARIS Cedex 07.
            </p>
          </div>
          <div className="bottom-form">
            <button type="button" onClick={onBack} className="previous-step">
              Retour
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
