import React from "react";

import useQuery from "hooks/useQuery";
import PreLoginHeader from "components/SmsAccredHeader";

export default function Error() {
  let query = useQuery();
  let message = query.get("msg");

  return (
    <div className="flex items-center justify-center mt-32 align-middle">
      <div className="page-container">
        <div className="w-full form-container">
          <PreLoginHeader />
          <div id="error">
            <p className="pb-4">
              Le lien que vous avez utilisé n’est pas valide ou a expiré.
              <br />
              Veuillez vous rapprocher de votre correspondant Fit&Proper pour
              obtenir l’envoi d’un nouveau lien.
              <br />
              <br />
              Merci.
            </p>
            {message && <p className="text-red-600">{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
