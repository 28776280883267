import React from "react";
import { useWatch } from "react-hook-form";

import { FONCTION_TYPE } from "../lib/fonctionNames";
import Input from "./form/Input";

export default function NbJoursParAn({ form, prefix, input, idx }) {
  const inputName = prefix + input.name;
  const { errors } = form;

  const fonctionId = useWatch({
    control: form.control,
    name: prefix + "fonctionId",
    defautValue: "autre",
  });

  const typeFonction = FONCTION_TYPE[fonctionId];

  let label = "Nombre de jours par an";

  if (typeFonction === "mandat-non-executif") {
    label = "Nombre de réunions par an";
  }

  return (
    <Input
      key={`section-${idx}-input`}
      form={form}
      //ref={register(input.options)}
      type="number"
      name={inputName}
      label={label}
      placeholder={input.placeholder}
      helpText={input.helpText}
      value={form.watch(inputName)}
      error={errors[inputName] && errors[inputName].message}
      // autoFocus={input.autoFocus}
      //disabled={disable.includes(input.name)}
      {...input.options}
    />
  );
}
