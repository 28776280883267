import React from "react";
import { Link, useLocation } from "react-router-dom";

import logoBPCE from "assets/images/logo_groupe_bpce.png";

export default function NoticeRGPD(_props) {
  const location = useLocation();

  return (
    <div key={location.pathname} className="w-full rgpd">
      <div className="top-bar">
        <h1 className="font-serif text-xl uppercase text-purple">
          Fit&nbsp;<span className="text-pink">&</span>&nbsp;Proper
        </h1>
        <div className="hidden lg:visibles separator" />
        <img
          src={logoBPCE}
          className="hidden lg:block"
          alt="logo du groupe BPCE"
        />
      </div>

      <div className="page-container">
        <div className="form-container">
          <h2>Mentions Légales</h2>

          <div className="form-content">
            <p>Ce site est édité par BPCE ci-après «&nbsp;l’Éditeur ».</p>

            <table>
              <tbody>
                <tr>
                  <th>Éditeur</th>
                  <td>BPCE</td>
                </tr>
                <tr>
                  <th>Forme Juridique</th>
                  <td>
                    Société anonyme à directoire et conseil de surveillance au
                    capital de 173&nbsp;613&nbsp;700&nbsp;€
                  </td>
                </tr>
                <tr>
                  <th>Siège Social</th>
                  <td>
                    50 avenue Pierre Mendès France – 75201&nbsp;Paris
                    Cedex&nbsp;13
                  </td>
                </tr>
                <tr>
                  <th>Nª SIREN</th>
                  <td>493 455 042 RCS Paris</td>
                </tr>
                <tr>
                  <th>Nª ind d’ident. intracommunautaire</th>
                  <td>FR 26 493 455 042</td>
                </tr>
                <tr>
                  <th>Contact</th>
                  <td>fitnproper@bpce.fr</td>
                </tr>
                <tr>
                  <th>Directeur de Publication</th>
                  <td>M. Laurent Mignon, Président du Directoire</td>
                </tr>
                <tr>
                  <th>Hébergeur</th>
                  <td>
                    Dénomination&nbsp;: Audience Pro
                    <br />
                    Adresse&nbsp;: 30 rue Étienne Dolet, 94230&nbsp;Cachan
                  </td>
                </tr>
              </tbody>
            </table>

            <h4>Condition d’accès et d’uilisation</h4>

            <p>
              L'utilisateur du site reconnaît avoir pris connaissance des
              présentes informations légales et s'engage à les respecter.
            </p>
            <p>
              Pour des raisons de maintenance mais également en cas de force
              majeure, difficultés informatiques, difficultés liées aux réseaux
              de télécommunications ou autres difficultés techniques, l’Éditeur
              pourra interrompre l'accès au site et ne saurait être tenu
              responsable de l'impossibilité d'accéder au présent site.
            </p>
            <p>
              L'accès à tout ou partie des produits et services décrits sur ce
              site peut faire l'objet de restrictions à l'égard de certaines
              personnes ou de certains pays. Aucun des produits ou services
              présentés ici ne sera fourni à une personne si la loi de son pays
              d'origine, ou de tout autre pays qui la concernerait, l'interdit.
            </p>

            <h4>
              Informations mises à disposition sur le site ou sur les sites
              tiers référencés
            </h4>

            <p>
              L’Éditeur s'efforce d'assurer l'exactitude et la mise à jour des
              informations à caractère général, notamment financières, diffusées
              sur ce site, dont il se réserve le droit de modifier et corriger
              le contenu à tout moment et sans préavis. Én conséquence,
              l’Éditeur ne garantit en aucune façon la complétude, la précision,
              l’exactitude, l’exhaustivité ou l’adéquation, des informations
              mises à disposition sur ce site, y compris l’ensemble des liens
              hypertextes ou toute autre liaison informatique utilisée,
              directement ou indirectement, à partir de ce site.
            </p>
            <p>L’Éditeur décline en conséquence toute responsabilité&nbsp;:</p>
            <ul>
              <li>
                en cas d'imprécision, inexactitude, erreur ou omission portant
                sur des informations disponibles sur le site ou sur les sites de
                tiers référencés à partir des liens hypertextes&#x202F;;
              </li>
              <li>d'une absence de disponibilité des informations&#x202F;;</li>
              <li>
                pour tous dommages, directs et/ou indirects, quelles qu'en
                soient les causes, origines, nature ou conséquences, provoqués à
                raison de l'accès de quiconque au site ou de l'impossibilité d'y
                accéder&#x202F;;
              </li>
              <li>
                de l'utilisation du site et/ou du crédit accordé à une
                quelconque information provenant directement ou indirectement du
                site&#x202F;;
              </li>
              <li>
                de décisions prises sur la base d'une information contenue sur
                le site ou sur le site de tiers référencés à partir des liens
                hypertextes, et de l'utilisation qui pourrait en être faite par
                des tiers.
              </li>
            </ul>

            <p>
              Il est strictement interdit de mettre en place un lien hypertexte
              en direction du site sans l'autorisation écrite et préalable de
              l’Éditeur. Toute demande tendant à cette fin doit être adressée à
              <a href="mailto:fitnproper@bpce.fr">fitnproper@bpce.fr</a>.
            </p>

            <h4>Données à caractère personnel</h4>
            <p>
              Les données à caractère personnel que vous nous communiquez par le
              biais des formulaires disponibles sur ce site (par exemple une
              demande de contact ou de renseignement, une simulation, un
              formulaire de souscription…) sont traitées par l’Éditeur,
              responsable de traitement, à des fins de gestion interne et pour
              répondre à votre demande.
            </p>
            <p>
              Les informations vous expliquant de quelle manière vos données
              sont obtenues, pourquoi elles sont traitées, avec qui elles sont
              susceptibles d’être partagées, les mesures mises en œuvre pour
              assurer leur confidentialité et leur sécurité figurent dans la
              Notice d’information sur les traitements des données à caractère
              personnel de l’Éditeur. Elle rappelle également les droits dont
              vous disposez et comment les exercer.
            </p>
            <p>
              Pour en savoir plus sur notre notice d’information sur le
              traitement des données personnelles&nbsp;:
              <Link to="rgpd">Notice RGPD Fit & Proper</Link>
            </p>

            <h4>Cookies – traceurs</h4>
            <p>
              Un cookie est un mini fichier contenant quelques lignes de
              caractères alphanumériques placé sur votre disque dur par le
              serveur du site ou de l’application mobile que vous visitez, ou
              par un serveur tiers (par exemple un service de web analytique),
            </p>
            <p>Il contient plusieurs données&nbsp;:</p>

            <ul>
              <li>le nom du serveur qui l’a déposé,</li>
              <li>un identifiant sous forme de numéro unique,</li>
              <li>éventuellement une date d’expiration.</li>
            </ul>

            <p>
              Les cookies servent à faciliter la navigation et à rationaliser
              les procédures d’enregistrement ou de mesure d’audience.
            </p>
            <p>Le site Fit & Proper n’utilise ni cookies, ni traceurs.</p>

            <h4>Propriété intellectuelle</h4>
            <p>
              L'ensemble des contenus (textes, sons, marques, logos,
              photographies, etc.), figurant sur les pages de ce site sont la
              propriété exclusive de l’Éditeur. Toute reproduction,
              représentation, diffusion ou extraction non autorisée, en tout ou
              partie, du contenu du site constituerait une contrefaçon des
              droits de propriété intellectuelle de l’Editeur susceptible
              d'engager la responsabilité civile et pénale du contrefacteur.
            </p>
            <h4>Droit applicable</h4>
            <p>Ce site est soumis à la loi française.</p>
            <h4>Crédits</h4>
            <p>Sans objet</p>
          </div>
        </div>
      </div>
    </div>
  );
}
