import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useUser } from "stores/user";
import { useForms } from "stores/form";
import Input from "components/form/Input";

import PreLoginHeader from "components/SmsAccredHeader";
import { reportError } from "lib/error-report";

export default function SmsValidationPage({ history, location }) {
  const candidatureToken = location.state?.candidatureToken;

  const [maskSendNew, setMaskSendNew] = useState(false);
  const { token, error, setError, sendNewCode, checkValidationCode } = useUser(
    ({ token, sendNewCode, error, setError, checkValidationCode }) => ({
      token,
      error,
      setError,
      checkValidationCode,
      sendNewCode,
    })
  );

  const { setForm } = useForms(({ setForm }) => ({ setForm }));

  const form = useForm();
  const { errors, handleSubmit, watch } = form;

  const onSubmit = async (params) => {
    setError(null);
    try {
      const { steps } = await checkValidationCode({
        token,
        code: params.validationCode,
        candidatureToken,
      });

      if (steps) {
        Object.keys(steps).map((step) => setForm(step, steps[step]));
        history.replace(`/welcome`);
        return;
      }
    } catch (err) {
      setError("Une erreur s’est produite, veuillez réessayer plus tard");
      reportError(err);
    }
  };

  const handleSendNew = useCallback(() => {
    sendNewCode(token);
    setMaskSendNew(true);
  }, [token, sendNewCode, setMaskSendNew]);

  return (
    <div className="flex items-center justify-center mt-32 align-middle sms-validation">
      <div className="page-container">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full form-container"
        >
          <PreLoginHeader />
          <p>Veuillez saisir le code qui vous a été envoyé pas SMS:</p>
          <Input
            form={form}
            key={`section-password`}
            // ref={register({ required: "requis" })}
            name="validationCode"
            label="Code de validation"
            value={watch("validation")}
            error={errors.password && errors.password.message}
            required={"Champ obligatoire"}
            autofocus={true}
          />
          <p className="text-red-500">{error && error.message}</p>
          <div className="flex items-center justify-between w-full">
            {maskSendNew ? (
              <div></div>
            ) : (
              <button
                className={`${maskSendNew ? "hidden" : ""} text-blue-600`}
                type="button"
                onClick={handleSendNew}
              >
                Code non reçu ? Cliquez ici pour le renvoyer.
              </button>
            )}
            <button className="action-button" type="submit">
              Valider
            </button>
          </div>
          <span className="text-sm text-red-500">
            {errors && errors.validationCode && errors.validationCode.message}
          </span>
        </form>
      </div>
    </div>
  );
}
