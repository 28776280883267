/*

Directeur(trice) général(e)                              , fonction exécutive
Président(e) du directoire                         , fonction exécutive
Directeur(trice) général(e)délégué                       , fonction exécutive
Directeur(trice) général(e)adjoint                       , fonction exécutive
Membre du directoire                            , fonction exécutive
Président-directeur(trice) général(e)                    , fonction exécutive
Gérant                                          , fonction exécutive
Président(e) délégué                               , fonction exécutive
Président(e) de SAS                                , fonction exécutive
Administrateur(trice) délégué                          , fonction exécutive
Président(e) du conseil d’administration           , fonction non-exécutive
Président(e) du conseil de surveillance            , fonction non-exécutive
Président(e) de l’organe collégial de surveillance , fonction non-exécutive
Membre du conseil d’administration              , fonction non-exécutive
Membre du conseil de surveillance               , fonction non-exécutive
Membre de l’organe collégial de surveillance    , fonction non-exécutive
Président(e) du comité d’audit                     , fonction non-exécutive
Président(e) du comité des rémunérations           , fonction non-exécutive
Président(e) du comité des risques                 , fonction non-exécutive
Président(e) du comité des nominations             , fonction non-exécutive
Autre (préciser)

*/

export const MANDATS = {
  "directeur-general": "Directeur(trice) général(e)",
  "president-du-directoire": "Président(e) du directoire",
  "directeur-general-delegue": "Directeur(trice) général(e) délégué(e)",
  "directeur-general-adjoint": "Directeur(trice) général(e) adjoint(e)",
  "membre-du-directoire": "Membre du directoire",
  "president-directeur-general": "Président-directeur général",
  gerant: "Gérant",
  "president-delegue": "Président(e) délégué",
  "president-de-sas": "Président(e) de SAS",
  "administrateur-delegue": "Administrateur(trice) délégué",
  "president-du-conseil-d-administration":
    "Président(e) du conseil d’administration",
  "president-du-conseil-de-surveillance":
    "Président(e) du conseil de surveillance",
  "president-de-l-organe-collegial-de-surveillance":
    "Président(e) de l’organe collégial de surveillance",
  "membre-du-conseil-d-administration": "Membre du conseil d’administration",
  "membre-du-conseil-de-surveillance": "Membre du conseil de surveillance",
  "membre-de-l-organe-collegial-de-surveillance":
    "Membre de l’organe collégial de surveillance",
  "president-du-comite-d-audit": "Président(e) du comité d’audit",
  "president-du-comite-des-remunerations":
    "Président(e) du comité des rémunérations",
  "president-du-comite-des-risques": "Président(e) du comité des risques",
  "president-du-comite-des-nominations":
    "Président(e) du comité des nominations",
  "chef-de-la-fonction-de-gestion-des-risques":
    "Chef(fe) de la fonction de gestion des risques",
  "chef-de-la-fonction-de-conformite": "Chef(fe) de la fonction de conformité",
  "chef-de-la-fonction-d-audit-interne":
    "Chef(fe) de la fonction d’audit interne",
  // Autres
  "commissaire-aux-comptes-statutaire": "Commissaire aux comptes statutaire",
  mandataire: "Mandataire",
  autre: "Autre",
};

export const FONCTIONS = {
  "directeur-general": "Directeur(trice) général",
  "president-du-directoire": "Président(e) du directoire",
  "directeur-general-delegue": "Directeur(trice) général(e) délégué(e)",
  "directeur-general-adjoint": "Directeur(trice) général(e) adjoint(e)",
  "membre-du-directoire": "Membre du directoire",
  "president-directeur-general": "Président-directeur(trice) général(e)",
  "administrateur-delegue": "Administrateur(trice) délégué(e)",
  "president-du-conseil-d-administration":
    "Président(e) du conseil d’administration",
  "president-du-conseil-de-surveillance":
    "Président(e) du conseil de surveillance",
  "president-de-l-organe-collegial-de-surveillance":
    "Président(e) de l’organe collégial de surveillance",
  "vice-president-du-conseil-d-administration":
    "Vice-président(e) du conseil d’administration",
  "vice-president-du-conseil-de-surveillance":
    "Vice-président(e) du conseil de surveillance",
  "vice-president-de-l-organe-collegial-de-surveillance":
    "Vice-président(e) de l’organe collégial de surveillance",
  "membre-du-conseil-d-administration": "Membre du conseil d’administration",
  "membre-du-conseil-de-surveillance": "Membre du conseil de surveillance",
  "membre-de-l-organe-collegial-de-surveillance":
    "Membre de l’organe collégial de surveillance",
  "directeur-financier": "Directeur(trice) financier(ère)", // CFO
  "directeur-des-risques": "Directeur(trice) des risques", // CRO
  "directeur-des-risques-financiers": "Directeur(trice) des risques financiers", // CFRO
  "directeur-des-systemes-d-information":
    "Directeur(trice) des systèmes d’information",
  autre: "Autre",
};

export const RESPONSABILITES = {
  "president-du-comite-d-audit": "Président(e) du comité d’audit",
  "membre-du-comite-d-audit": "Membre du comité d’audit",
  "president-du-comite-des-remunerations":
    "Président(e) du comité des rémunérations",
  "membre-du-comite-des-remunerations": "Membre du comité des rémunérations",
  "president-du-comite-des-risques": "Président(e) du comité des risques",
  "membre-du-comite-des-risques": "Membre du comité des risques",
  "president-du-comite-des-nominations":
    "Président(e) du comité des nominations",
  "membre-du-comite-des-nominations": "Membre du comité des nominations",
  "president-du-comite-rse": "Président(e) du comité RSE",
  "membre-du-comite-rse": "Membre du comité RSE",
  autre: "Autre",
};

export const FONCTIONS_LABELS = Object.keys(FONCTIONS);
export const FONCTIONS_CHOICES = Object.entries(FONCTIONS).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const MANDATS_LABELS = Object.keys(MANDATS);
export const MANDATS_CHOICES = Object.entries(MANDATS).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const RESPONSABILITES_LABELS = Object.keys(RESPONSABILITES);
export const RESPONSABILITES_CHOICES = Object.entries(RESPONSABILITES).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const FONCTION_TYPE = {
  //CEO
  "president-directeur-general": "mandat-executif",
  //Deputy CEO
  "directeur-general-adjoint": "mandat-executif",
  gerant: "mandat-executif",
  "dirigeant-executif": "mandat-executif",
  "directeur-general": "mandat-executif",
  "directeur-financier": "mandat-executif",
  "directeur-des-risques": "mandat-executif",
  "directeur-des-risques-financiers": "mandat-executif",
  "directeur-des-systemes-d-information": "mandat-executif",
  "directeur-general-delegue": "mandat-executif",
  "president-du-directoire": "mandat-executif",
  "president-delegue": "mandat-executif",
  "membre-du-directoire": "mandat-executif",
  "president-de-sas": "mandat-executif",
  "administrateur-delegue": "mandat-executif",
  "president-du-conseil-d-administration": "mandat-non-executif",
  "vice-president-du-conseil-d-administration": "mandat-non-executif",
  "membre-du-conseil-d-administration": "mandat-non-executif",
  "president-du-conseil-de-surveillance": "mandat-non-executif",
  "vice-president-du-conseil-de-surveillance": "mandat-non-executif",
  "membre-du-conseil-de-surveillance": "mandat-non-executif",
  "president-de-l-organe-collegial-de-surveillance": "mandat-non-executif",
  "vice-president-de-l-organe-collegial-de-surveillance": "mandat-non-executif",
  "membre-de-l-organe-collegial-de-surveillance": "mandat-non-executif",
  "president-du-comite-d-audit": "mandat-non-executif",
  "membre-du-comite-d-audit": "mandat-non-executif",
  "president-du-comite-des-remunerations": "mandat-non-executif",
  "membre-du-comite-des-remunerations": "mandat-non-executif",
  "president-du-comite-des-risques": "mandat-non-executif",
  "membre-du-comite-des-risques": "mandat-non-executif",
  "president-du-comite-des-nominations": "mandat-non-executif",
  "membre-du-comite-des-nominations": "mandat-non-executif",
  "chef-de-la-fonction-de-gestion-des-risques": "mandat-non-executif",
  "chef-de-la-fonction-de-conformite": "mandat-non-executif",
  "chef-de-la-fonction-d-audit-interne": "mandat-non-executif",
  mandataire: undefined,
  autre: undefined,
};

export const TYPES = {
  "mandat-executif": "Fonction exécutive",
  "mandat-non-executif": "Fonction non-exécutive",
  "mandat-function-holder": "Titulaire d’une fonction clé",
  "mandat-branch-manager": "Poste de Directeur(trice) d’une succursale",
  "mandat-autre": "Fonction non constitutive d’un mandat social",
};

export const TYPES_CHOICES = Object.entries(TYPES).map(([id, name]) => ({
  id,
  name,
}));
