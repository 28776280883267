import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { useNotify } from "stores/notify";
import useUsers from "hooks/useUsers";
import Input from "components/form/Input";

import PreLoginHeader from "components/SmsAccredHeader";

export default function RequestAccess() {
  const { message, setErrorMessage } = useNotify(
    ({ message, setErrorMessage }) => ({
      message,

      setErrorMessage,
    })
  );

  const { requestAccess } = useUsers();
  const [email, setEmail] = useState(null);

  const [success, setSucccess] = useState(false);
  const onSubmit = async (params) => {
    try {
      await requestAccess(params.email);
      setEmail(params.email);
      setSucccess("true");
      setErrorMessage(undefined);
    } catch (err) {
      let message = err.message;

      if (err.redirect) {
        message += ` (${err.redirectMessage || "vous allez être redirigé·e"})`;

        setTimeout(() => {
          window.location.href = err.redirect;
        }, 5000);
      }
      setErrorMessage(message);
    }
  };

  const form = useForm();
  const { register, handleSubmit, errors, watch } = form;

  return (
    <div className="flex items-center justify-center mt-32 align-middle">
      <div className="page-container">
        <form
          className="w-full form-container"
          onSubmit={handleSubmit(onSubmit)}
        >
          <PreLoginHeader />
          {!success && (
            <>
              <p>
                Veuillez saisir votre adresse email pour démarrer une demande
                d'accréditation :
              </p>
              <Input
                key={`section-email`}
                ref={register({ required: "Champ obligatoire" })}
                type="text"
                name="email"
                form={form}
                label="Email"
                placeholder="Email"
                value={watch("email")}
                error={errors.email && errors.email.message}
                required={"Champ obligatoire"}
                autofocus={true}
              />

              <div className="flex items-center justify-end w-full">
                <button className="action-button" type="submit">
                  Envoyer la demande d'accès
                </button>
              </div>
            </>
          )}
          {message && <div className="text-red-500">{message.text}</div>}
          {success && (
            <div>
              Un email vous a été envoyé à {email}, vous invitant à créer votre
              profil
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
