import React from "react";

import { useUser } from "../stores/user";

const R = /^\w+\[(\d)\]/;

function getIndex(prefix) {
  const index = R.exec(prefix);

  if (!index) {
    return -1;
  }

  return parseInt(index[1], 10);
}

function getFormationDays(formations = []) {
  return formations.reduce((acc, { nombreJours }) => {
    return acc + parseInt(nombreJours || 0, 10);
  }, 0);
}

function getOverride(candidature, index) {
  const mandatFonctions = candidature?.mandatFonctions;

  if (!mandatFonctions) return [false, null];

  const mandatFonction = mandatFonctions[index];
  const { useJoursParAnManuels, joursParAnManuels } = mandatFonction || {
    useJoursParAnManuels: false,
  };

  return [!!useJoursParAnManuels, joursParAnManuels ?? 0];
}

export default function ShowTempsConsacre(props) {
  const { form, prefix } = props;
  // primary is the first mandatfonction.
  // It should the formation days
  const index = getIndex(prefix);
  const isPrimary = index === 0;
  const mandatFonctions = form.watch("mandatFonction");
  const candidature = useUser(({ candidature }) => candidature);

  if (mandatFonctions && index >= 0) {
    const mandatFonction = mandatFonctions[index];
    const tempsJoursAnnees = mandatFonction.tempsJoursAnnees;
    const [useJoursParAnManuels, joursParAnManuels] = getOverride(
      candidature,
      index
    );

    let days = tempsJoursAnnees;
    if (useJoursParAnManuels) {
      days = joursParAnManuels;
    }

    const formationDays = getFormationDays(candidature.formations);

    return (
      <div className="w-full">
        <div className="font-bold">
          Nombres de jours par an consacrés à cette fonction ou mandat&nbsp;:
        </div>
        <span className="text-xl" style={{ fontSize: "1.25em" }}>
          {days} jours par an
          {formationDays && isPrimary ? (
            <> et {formationDays} jours de formation</>
          ) : (
            ""
          )}
        </span>
        {useJoursParAnManuels && (
          <div className="p-2 text-sm bg-gray-200 opacity-75 form-row">
            L’entité force le nombre de jours par an consacrés à cette fonction.
            <br />
            La valeur calculée initiale était de {tempsJoursAnnees} jours par
            (sans compter les jours de formation)
          </div>
        )}
      </div>
    );
  }

  return null;
}
