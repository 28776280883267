import React, { useRef } from "react";
import Files from "react-files";
import { useForms } from "stores/form.js";
import { useUser } from "stores/user.js";
import FileDl from "./FileDl.js";
import { reportError } from "lib/error-report.js";

export default React.forwardRef(
  ({ label, name, required, onChange, className, value, options }, _ref) => {
    const refs = useRef();
    const {
      uploadFile,
      removeFile,
    } = useForms(({ uploadFile, removeFile }) => ({ uploadFile, removeFile }));

    const { token, candidature } = useUser(({ token, candidature }) => ({
      token,
      candidature,
    }));

    let classes = className;

    if (required) {
      classes += " required";
    }

    // Ensure there is value, remove possible
    // null/falsy values in there that may crash
    // tha app
    value = (value || []).filter((v) => !!v);

    const onFilesError = (error, _file) => {
      reportError(error);
    };

    const filesRemoveOne = async (file) => {
      await removeFile(token, name, file.id);
      onChange(value.filter((f) => f.id !== file.id));
    };

    const handleChanges = async (f) => {
      if (value) f = f.filter((f) => !value.find((v) => v.name === f.name));
      const result = await uploadFile(token, name, f, candidature);
      onChange(
        result.files.map((x) => ({ name: x.name, type: x.type, id: x.id }))
      );
    };

    return (
      <label className={classes} htmlFor={name}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {options && options.limit && (
          <span className="pl-4 text-red-500">
            {options.limit} document maximum
          </span>
        )}
        <Files
          ref={(r) => (refs.current = r)}
          className="mt-3 cursor-pointer files-dropzone-list"
          style={{ height: "100px" }}
          onChange={(f) => {
            handleChanges(f);
          }}
          onError={onFilesError}
          multiple
          maxFiles={options && options.limit}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          Glissez-déposez vos fichiers ou cliquez ici pour téléverser vos
          documents
        </Files>
        {value && value.length > 0 ? (
          <>
            <div className="files-list">
              <ul>
                {value.map(
                  (file) =>
                    file && (
                      <li className="relative files-list-item" key={file.id}>
                        <FileDl file={file} />
                        <div
                          id={file.id}
                          className="absolute top-0 right-0 px-3 my-auto mr-3 text-xl font-bold text-red-500 bg-gray-200 rounded-full cursor-pointer bg-opacity-75"
                          onClick={() => filesRemoveOne(file)}
                        >
                          x
                        </div>
                      </li>
                    )
                )}
              </ul>
            </div>
          </>
        ) : null}
      </label>
    );
  }
);
