import { useEffect, useState } from "react";

import { useForms } from "stores/form";
import { useUser } from "stores/user";

const PREFIX_INDEX_REGEX = /\[(\d+)\]$/;
function getPrefixIndex(prefix) {
  var match = PREFIX_INDEX_REGEX.exec(prefix);

  if (match && Array.isArray(match) && match[1]) {
    const index = parseInt(match[1], 10);

    if (!isNaN(index)) {
      return index;
    }
  }

  throw new Error(`invalid prefix ${prefix}`);
}

export default function TempsConsacre({ form, prefix }) {
  if (prefix.endsWith(".")) {
    prefix = prefix.substring(0, prefix.length - 1);
  }

  const mandatIndex = getPrefixIndex(prefix);

  const { computeDedicatedDays } = useForms(({ computeDedicatedDays }) => ({
    computeDedicatedDays,
  }));
  const { token, candidature } = useUser(({ token, candidature }) => ({
    token,
    candidature,
  }));
  const mandatFonction = form.watch(prefix, null);

  const [dedicatedDays, setDedicatedDays] = useState(0);

  useEffect(() => {
    (async () => {
      if (mandatFonction) {
        let d = await computeDedicatedDays(
          token,
          candidature.id,
          mandatFonction
        );

        setDedicatedDays(parseFloat(d, 10));
      }
    })();
  }, [
    mandatFonction,
    candidature.id,
    token,
    computeDedicatedDays,
    mandatIndex,
  ]);

  useEffect(() => {
    form.setValue(`${prefix}.tempsJoursAnnees`, parseFloat(dedicatedDays, 10), {
      shouldDirty: true,
    });

    const hours = ((parseFloat(dedicatedDays, 10) * 8) / 46).toFixed(1);

    form.setValue(`${prefix}.tempsHeuresSemaines`, parseFloat(hours, 10), {
      shouldDirty: true,
    });
    // eslint-disable-next-line
  }, [dedicatedDays, prefix]);

  return null;
}
