import React from "react";
import { Link } from "react-router-dom";

import logoBPCE from "assets/images/logo_groupe_bpce.png";
import { useUser } from "stores/user";
import Footer from "components/Footer";

export default function StepOne() {
  const { candidature } = useUser(({ candidature }) => ({ candidature }));

  return (
    <div className="flex flex-col w-full" style={{ minHeight: "100vh" }}>
      <div className="flex-grow page-container">
        <div className="welcome-card">
          <div className="welcome-card-left">
            <h1 className="text-purple">
              Fit&nbsp;<span className="text-pink">&</span>&nbsp;Proper
            </h1>
            <img src={logoBPCE} alt="logo du groupe BPCE" />
          </div>

          <div className="welcome-card-right">
            <h2>
              Bonjour et bienvenue dans <br />
              l’espace de complétude de
              <br />
              votre dossier «&nbsp;Fit & Proper&nbsp;»&#x202F;!
            </h2>
            <p>
              Votre dossier est composé des questions devant obligatoirement
              être renseignées pour la présentation de votre candidature
              (signalées par le symbole *).
            </p>
            <p>
              Afin de vous simplifier la complétude du formulaire, certaines
              questions ont été pré-remplies sur la base d’informations dont
              nous disposons par ailleurs. Les réponses vous sont toujours
              présentées afin que vous puissiez les amender si nécessaire.
            </p>
            <p>
              Vous risquez d’être interrompu·e&#x202F;? Aucun problème, vos
              réponses sont enregistrées automatiquement, vous pourrez vous
              reconnecter ultérieurement pour terminer de compléter votre
              dossier.
            </p>
            <p>
              <em>C’est à vous&#x202F;!</em>
            </p>
            <div className="welcome-card-button">
              <Link to="/etape/1" className="action-button inverted">
                Compléter mon formulaire
              </Link>
            </div>
          </div>
        </div>
      </div>
      {candidature && <Footer entite={candidature.entite} />}
    </div>
  );
}
