import React, { useEffect } from "react";
import decamelize from "decamelize";
import { fadeIn, fadeOut, getClosest } from "lib/utils";

export default () => {
  useEffect(() => {
    document.getElementById("toggle-drawer").checked = false;
    document
      .querySelector("#toggle-drawer")
      .addEventListener("change", function () {
        if (this.checked) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "scroll";
        }
      });
  }, []);

  const labelsBySection = Object.values(
    document.querySelectorAll(".form-container label")
  ).reduce((sections, elem) => {
    try {
      let labelId = elem.getAttribute("for");
      let sectionName = labelId.match(new RegExp("[a-zA-Z]*(?=\\[)"));
      let sectionId = labelId.match(new RegExp("(?<=\\[)[0-9]*(?=\\])"));
      if (elem.getAttribute("id")) {
        return sections;
      }
      if (
        elem.classList.contains("rating-radio") ||
        elem.classList.contains("radio-label")
      ) {
        labelId = elem.querySelector("input").name;
        const spanText = elem.parentElement.parentElement.querySelector("span")
          .innerText;
        const fakeSpan = document.createElement("span");
        fakeSpan.innerText = spanText;
        fakeSpan.style.display = "none";
        fakeSpan.attributes.for = elem.getAttribute("for");

        elem.insertBefore(fakeSpan, elem.firstChild);
      }
      if (sectionName && sectionId) {
        const id = `${sectionName}-${sectionId}`;
        if (!sections[id]) {
          sections[id] = [elem];
        } else {
          sections[id].push(elem);
        }
      } else {
        sections[labelId] = [elem];
      }
    } catch (err) {
      //
    }
    return sections;
  }, {});

  return Object.keys(labelsBySection).map((s) => {
    const section = labelsBySection[s];
    return (
      <div
        key={s + section.length}
        className="flex flex-col px-6 py-2 text-lg bg-white shadow-md text-pink"
      >
        {section.length > 1 && (
          <h3 className="font-bold">{decamelize(s, " ")}</h3>
        )}
        {section.map((elem) => {
          if (
            elem.getAttribute("for") &&
            elem.getAttribute("for").indexOf("yes-") < 0
          ) {
            const label =
              elem.querySelector("span") &&
              elem.querySelector("span").innerText;
            if (label) {
              return (
                <div
                  key={`toc-${label}-${elem.getAttribute("for")}`}
                  className="flex flex-row justify-between w-full cursor-pointer"
                  onClick={() => {
                    const collapsed = document.querySelectorAll(".expandable");

                    const collapsedParent = Object.values(collapsed).find((c) =>
                      c.contains(elem)
                    );

                    if (
                      !!collapsedParent &&
                      !(
                        collapsedParent.previousSibling.getAttribute("type") ===
                        "button"
                      )
                    ) {
                      collapsedParent.previousSibling.click();
                    }

                    document.getElementById("toggle-drawer").checked = false;
                    var event = new CustomEvent("change");

                    document
                      .getElementById("toggle-drawer")
                      .dispatchEvent(event);

                    const element = document.getElementById(
                      elem.getAttribute("for")
                    );

                    const headerOffset = 300;
                    const bodyPosition = document.body.getBoundingClientRect()
                      .top;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition =
                      -bodyPosition + elementPosition - headerOffset;

                    window.scrollTo({
                      behavior: "smooth",
                      top: offsetPosition,
                    });
                    const formRow = getClosest(element, ".form-row");

                    setTimeout(() => {
                      fadeOut(formRow, 0.2);
                    }, 0);
                    setTimeout(() => {
                      fadeIn(formRow, 0.2);
                    }, 400);
                    setTimeout(() => {
                      fadeOut(formRow, 0.2);
                    }, 800);
                    setTimeout(() => {
                      fadeIn(formRow, 0.2);
                    }, 1200);
                  }}
                >
                  <span>
                    {label.length > 30
                      ? label.replace(label.substring(30, label.length), "...")
                      : label}
                  </span>
                  <span>⇾</span>
                </div>
              );
            }
          }

          return null;
        })}
      </div>
    );
  });
};
