import React from "react";
import ss from "sourcemapped-stacktrace";

const { REACT_APP_API_URL } = process.env;

function mapStackTrace(error) {
  return new Promise((resolve) => {
    ss.mapStackTrace(error.stack || "", (stack) => {
      const e = new Error(error.message);
      e.stack = stack.join("\n");

      resolve(e);
    });
  });
}

/**
 * @typedef {Object} ErrorReport
 * @property {string} service
 * @property {string} url
 * @property {{ message: string, stack: string }} error
 */

/**
 * @typedef {Object} CacheObject
 * @property {ErrorReport}  report
 * @property {Date} cachedAt
 * @property {Date} validUntil
 */

/**
 * @type {Object<string, CacheObject>}
 */
const errorCache = {};

/**
 * @param {ErrorReport} errorReport
 * @returns {string} id
 */
function createCacheKey(errorReport) {
  return `${errorReport.url}#${errorReport.error.message}#${errorReport.error.stack}`;
}

/**
 * @param {ErrorReport} errorReport
 * @returns {boolean}
 */
function isCached(errorReport) {
  const key = createCacheKey(errorReport);

  if (Object.hasOwnProperty(errorCache, key)) {
    const obj = errorCache[key];
    const now = new Date();
    const diff = +now - obj.validUntil;

    // Clear the cache if the error is more than
    // the validity time for that object
    if (diff > 0) {
      delete errorCache[key];
    } else {
      return true;
    }
  }

  return false;
}

/**
 * @param {ErrorReport} errorReport
 */
function cache(errorReport) {
  const validUntil = new Date();
  validUntil.setMinutes(validUntil.getMinutes() + 10);

  errorCache[createCacheKey(errorReport)] = {
    report: errorReport,
    cachedAt: new Date(),
    validUntil: validUntil,
  };
}

/**
 * @param {Error} error
 */
export async function reportError(error) {
  if (!error) return;

  const mappedError = await mapStackTrace(error);

  /** @type {ErrorReport} */
  const errorReport = {
    service: "front candidat",
    url: window.location.href,
    error: {
      message: mappedError.message,
      stack: mappedError.stack,
    },
  };

  if (isCached(errorReport)) {
    return;
  }

  cache(errorReport);

  const body = JSON.stringify(errorReport);

  const response = await fetch(`${REACT_APP_API_URL}/report-error`, {
    method: "POST",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json; charset=utf-8",
      "Content-Length": body.length,
    },
    body: body,
  });

  if (response.status !== 200) {
    const text = await response.text();

    try {
      const { message, error } = JSON.parse(text);

      if (message) {
        console.error(message);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error, errorInfo) {
    if (errorInfo?.componentStack) {
      error.message += errorInfo.componentStack;
    }

    console.error("error caught by error boundary");

    reportError(error);
  }

  render() {
    const { hasError, error } = this.state;

    if (!hasError) {
      return this.props.children || null;
    }

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          opacity: 0.5,
        }}
      >
        <h4 style={{ paddingBottom: "2em" }}>
          <strong>Une erreur est survenue</strong>
        </h4>

        <p>
          Notre équipe en a été informée et mettra tout en œuvre pour résoudre
          le problème au plus vite.
        </p>

        <p>Message de l’erreur&nbsp;: {error.message.split("\n")[0]}</p>
      </div>
    );
  }
}
