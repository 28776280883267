import React, { useEffect, useState, useRef, useCallback } from "react";

import { useForms } from "stores/form";
import { useUser } from "stores/user";
import { useForm, useWatch } from "react-hook-form";
import getSummary from "components/form/TableOfContent";

import FormDefinitions, { numberOfSteps } from "../forms";

import FormRow from "components/form/FormRow";
import Footer from "components/Footer";
import { useHistory } from "react-router-dom";

import logoBPCE from "assets/images/logo_groupe_bpce.png";
import { flattenErrors } from "lib/utils";

const FORBIDDEN_CHARS = ["&", "/", "€", "%", "«", "»", '"'];
const noSpecialCharacters = (value) => {
  if (
    value &&
    typeof value === "string" &&
    FORBIDDEN_CHARS.some((f) => value.includes(f))
  ) {
    return 'Les caractères &, /, €, «, », " et % ne sont pas permis';
  }

  return;
};

export default function Step(props) {
  const [step, setStep] = useState("etape-" + props.match.params.step);
  const Form = FormDefinitions[step];
  const position = Object.keys(FormDefinitions).indexOf(step);

  // Application Store
  const {
    error,
    saveFormToDb,
    setForm,
    setErrors,
    getForm,
    getErrors,
  } = useForms(
    ({ error, saveFormToDb, setForm, setErrors, getForm, getErrors }) => ({
      error,
      setForm,
      setErrors,
      getForm,
      getErrors,
      saveFormToDb,
    })
  );
  const { token, candidature } = useUser(({ token, candidature }) => ({
    token,
    candidature,
  }));

  // Errors for this step
  const [stepErrors, setStepErrors] = useState(getErrors()[step]);

  // Storing the step in a state prevents some renders when navigating
  useEffect(() => {
    setStep("etape-" + props.match.params.step);
  }, [props.match.params.step]);

  // Update the step errors after navigation
  useEffect(() => {
    setStepErrors(getErrors()[step]);
  }, [getErrors, setStepErrors, step]);

  // REACT HOOK FORM
  const f = useForm({
    defaultValues: {
      ...Form.defaultValues,
      ...getForm(step),
    },
  });

  const localErrors = { ...stepErrors, ...flattenErrors(f.errors) };

  // Initialized the form with stored values
  // when navigating
  const { reset } = f;

  const setCurrentStepData = (data) => {
    setForm(step, data);
    reset(data);
  };

  const history = useHistory();

  const onBack = useCallback(() => {
    const prevStep = parseInt(props.match.params.step, 10) - 1;

    history.push(`/etape/${prevStep}`);
  }, [history, props.match.params.step]);

  // Reset form values on load and back/forward
  const currentValues = useWatch({ control: f.control });
  useEffect(() => {
    if (currentValues) {
      setForm(step, currentValues);
    }
  }, [step, currentValues, setForm, Form]);

  // submission
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    f.handleSubmit(
      (data) => {
        setForm(step, data);
        setErrors(step, null);

        if (Form.next != null) {
          history.push(`/etape/${Form.next}`);
        } else {
          history.push("/sumup");
        }
      },
      // Errors are stored in sessionStorage
      // And we keep going forward with the form
      (errors) => {
        setForm(step, f.getValues());
        setErrors(step, errors);

        const uniqErrorTypes = new Set(errorTypes(errors));
        const hasBlockingErrors = Array.from(uniqErrorTypes).some((e) =>
          ["noSpecialCharacters", "min", "max"].includes(e)
        );

        if (hasBlockingErrors) {
          window.alert(
            "Certains champs contiennent des erreurs. Corrigez-les pour continuer."
          );
        } else if (
          window.confirm(
            "Certains champs sont manquants. Souhaitez-vous continuer quand-même ?"
          )
        ) {
          if (Form.next != null) {
            history.push(`/etape/${Form.next}`);
          } else {
            history.push("/sumup");
          }
        }
      }
    )(e);
  };

  function errorTypes(errors, acc = []) {
    return Object.values(errors).reduce((acc, error) => {
      if (Object.hasOwnProperty.call(error, "type")) {
        return [...acc, error.type];
      } else if (typeof error === "object" && !Array.isArray(error)) {
        return errorTypes(error, acc);
      } else if (Array.isArray(error)) {
        return error.reduce((acc, err) => errorTypes(err, acc), acc);
      }

      return acc;
    }, acc);
  }

  function recursiveNumberTwo(object, callback) {
    if (object === undefined || object === null) return;

    if (Array.isArray(object)) {
      object.forEach((o) => recursiveNumberTwo(o, callback));
    } else if (typeof object === "object") {
      if (
        Object.hasOwnProperty.call(object, "name") &&
        (object.type === "text" || object.type === "textarea" || !object.type)
      ) {
        callback(object);
      }

      Object.entries(object).forEach(
        ([k, o]) => k !== "options" && recursiveNumberTwo(o, callback)
      );
    }
  }

  const saveInterval = useRef(undefined);
  useEffect(() => {
    if (saveInterval.current !== undefined) {
      clearInterval(saveInterval.current);
    }

    function saveForm() {
      saveFormToDb(token, candidature.id);
    }

    saveInterval.current = setInterval(saveForm, 10000);

    return () => {
      if (saveInterval.current !== undefined) {
        clearInterval(saveInterval.current);
      }
    };
  }, [candidature.id, history, saveFormToDb, token]);

  useEffect(() => {
    if (error?.redirect) {
      const message =
        error.redirectMessage ||
        "Une erreur irrécupérable s’est produite pendant la sauvegarde de vorte formulaire";
      history.push(error.redirect + `?msg=${message}`);
    }
  }, [error, history]);

  const progress = Math.round((100 * position) / numberOfSteps);

  recursiveNumberTwo(Form.sections, (input) => {
    if (input?.options?.validate) {
      if (typeof input.options.validate === "function") {
        input.options.validate = {
          orig: input.options.validate,
        };
      }

      input.options.validate.noSpecialCharacters = noSpecialCharacters;
      return input;
    }

    if (input?.options) {
      input.options.validate = {
        noSpecialCharacters: noSpecialCharacters,
      };

      return input;
    }

    if (input) {
      input.options = {
        validate: {
          noSpecialCharacters: noSpecialCharacters,
        },
      };

      return input;
    }

    return input;
  });

  return (
    <div key={props.location.pathname} className="w-full">
      <div className="top-bar">
        <h1 className="font-serif text-xl uppercase text-purple">
          Fit&nbsp;<span className="text-pink">&</span>&nbsp;Proper
        </h1>
        <div className="hidden lg:visibles separator" />
        <img
          src={logoBPCE}
          className="hidden lg:block"
          alt="logo du groupe BPCE"
        />
        <label htmlFor="toggle-drawer" className="w-full text-right lg:hidden">
          ☰
        </label>
        <input type="checkbox" className="hidden" id="toggle-drawer" />
        <div className="fixed top-0 right-0 z-10 w-full h-full bg-white lg:hidden drawer">
          <label
            htmlFor="toggle-drawer"
            className="absolute top-0 right-0 m-3 mt-2 text-xl font-semibold text-black "
          >
            x
          </label>
          <span className="inline-block w-full mb-3 ml-4 text-xl text-center">
            Table des matières
          </span>
          <br />
          <span className="inline-block pb-4 ml-4 text-center">
            {Form.title}
          </span>
          <div className="flex flex-col h-full pb-10 overflow-scroll bg-gray-200">
            {getSummary()}
          </div>
        </div>
      </div>

      <div className="page-container">
        <div className="progress">
          <span>Taux d’avancement: {progress}%</span>

          <span className="progress-container">
            <span className="progress-bar" style={{ width: `${progress}%` }} />
          </span>
        </div>

        <form onSubmit={onSubmit} className="form-container">
          <h2>{Form.title}</h2>

          <div className="form-content">
            {Form.sections.map(({ title, rows }, sidx) => (
              <React.Fragment key={`section-${sidx}`}>
                {title && (
                  <div key={`section-${sidx}-title`} className="section-header">
                    {title}
                  </div>
                )}
                {rows &&
                  rows.map((row, ridx) => (
                    <FormRow
                      key={`section-${sidx}-${ridx}`}
                      inputs={row}
                      idx={`$${sidx}-${ridx}`}
                      form={f}
                      errors={localErrors}
                      setCurrentStepData={setCurrentStepData}
                    />
                  ))}
              </React.Fragment>
            ))}
            <span className="text-sm">
              <span className="text-2xl font-bold text-red-500">*</span> Champs
              requis
            </span>
          </div>

          <div className="bottom-form">
            {step !== "etape-1" && (
              <button type="button" onClick={onBack} className="previous-step">
                Étape précédente
              </button>
            )}
            {step === "etape-1" && <div />}
            <button type="submit" className="next-step">
              Étape suivante
            </button>
          </div>
        </form>
      </div>

      {candidature && <Footer entite={candidature.entite} />}
    </div>
  );
}
